import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Team_1 from '../assets/images/team1.png'
import Http from "../Http_Api";
import dataContext from '../context/data/dataContext';
export default function Employee() {
    const empty_team = useRef(0);
    const [data_employee_title, setData_employee_title] = useState([]);
    const [data_all_employee, setData_all_employee] = useState([]);
    const [data_employee_by_catagory, setData_employee_by_catagory] = useState([]);
    // const [user_type, setUser_type] = useState('worker');
    // const [user_catagory, setUser_catagory] = useState('');
    const [len, setLen] = useState(false);
    const context = useContext(dataContext);
    const { data, getData, StorageUrl_logo, StorageUrl_Image, multi_image_url } = context;
    const windoSCrollCount = useRef(0)
    useEffect(() => {



        if (windoSCrollCount.current === 0) {
            window.scrollTo({ top: 0, behavior: "smooth" })
            windoSCrollCount.current = 1
        }
        let scrolling = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop
        ele_len()
        return () => {
            ele_len()
        }
    }, []);
    let user_catagory = useRef('worker');

    const ele_len = () => {
        let all_element_children = document.getElementById("all")
        if (all_element_children !== undefined && all_element_children !== null) {

            all_element_children = all_element_children.getElementsByClassName('row')
            if (all_element_children !== undefined && all_element_children !== null) {
                all_element_children = all_element_children[0].childElementCount;
                if (all_element_children !== undefined && all_element_children !== null && all_element_children > 0) {
                    setLen(false);
                }
                else {
                    setLen(true);
                }
            }
            else {
                setLen(true);
            }
        }
        else {
            setLen(true);
        }


    }
    // const get_employee_by_catagorry = () => {

    //     if (user_catagory == 'worker') {
    //         user_catagory = ''
    //     }
    //     Http.get('/users/' + user_type + "/" + user_catagory).then((res, req) => {
    //         // let value = []
    //         // Object.keys(res.data).map((key) => {
    //         //     value.push(res.data[key]);
    //         // })
    //         setData_employee_by_catagory(res.data)
    //     })
    // }

    const Team_vissible = (e, name) => {
        let element = e.target.closest('.tablinks')
        let tablinks = document.getElementsByClassName('tablinks')
        for (let index = 0; index < tablinks.length; index++) {
            const element = tablinks[index];
            if (element.classList.contains('active')) {
                element.classList.remove('active');
            }
        }
        if (!element.classList.contains('active')) {
            element.classList.add('active');
        }


        let employee = document.getElementById(name);
        let employee_all = document.getElementsByClassName('team_member_by_catagory');
        for (let index = 0; index < employee_all.length; index++) {
            const element = employee_all[index];
            if (element.classList.contains('d-block')) {
                element.classList.add('d-none')
                element.classList.remove('d-block')
            }
        }
        // setUser_catagory(name);
        if (employee.classList.contains('d-none')) {
            employee.classList.add('d-block')
            employee.classList.remove('d-none')
        }
        user_catagory.current = name
        // get_all_employee();
        // get_employee_by_catagorry();
    }
    var team_teachers, team_employees_catagory_1, team_employees_catagory_2, team_employees_catagory_3;
    if (data.length > 0) {

        var { team_teachers, team_employees_catagory_1, team_employees_catagory_2, team_employees_catagory_3, } = data[0];
    }

    if (team_teachers && team_employees_catagory_1 && team_employees_catagory_2 && team_employees_catagory_3) {

        let s1 = [];
        let s2 = [];
        let s3 = [];
        let s4 = [];

        Object.keys(team_teachers).map((key) => {
            s1.push(team_teachers[key])
        })
        Object.keys(team_employees_catagory_1).map((key) => {
            s2.push(team_employees_catagory_1[key])
        })
        Object.keys(team_employees_catagory_2).map((key) => {
            s3.push(team_employees_catagory_2[key])
        })
        Object.keys(team_employees_catagory_3).map((key) => {
            s4.push(team_employees_catagory_3[key])
        })
        let s;
        team_teachers = s1
        team_employees_catagory_1 = s2
        team_employees_catagory_2 = s3
        team_employees_catagory_3 = s4

    }

    const Catagory_data_list = ['worker', 'advisoryBorde', 'official', 'teacher', 'employee'];

    const updatemptyteam = (number, incriment) => {
        if (number !== null && number !== undefined && number.length !== 0) {
            empty_team.current = number;
        }
        if (incriment) {
            empty_team.current++
        }
    }
    return (
        <>
            <section id="page-banner">
                <div className="banner-inner"

                    style={{ backgroundImage: `url(https://source.unsplash.com/random/1600x200/?islamic)` }}>

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="banner-contents">
                                    <h1 className='d-block'>সেবা প্রদানকারী ব্যক্তিগন</h1>
                                    <nav className="breadcrumbs " style={{ opacity: "1" }}>
                                        <Link to={'/'} className="breadcrumbs__item"><i className="text-black fa-solid fa-house"></i> হোম</Link>
                                        {/* <Link to={'/'} className="breadcrumbs__item">Sho</Link> */}
                                        {/* <Link to={'/'} className="breadcrumbs__item">Cart</Link> */}
                                        <Link to={'/employee'} className="breadcrumbs__item is-active">সেবা প্রদানকারী ব্যক্তিগন</Link>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="All_Employee">
                <div className="container" >
                    <div className="row" style={{ margin: '5% 0' }}>
                        <div className="col-12 col-md-12 mx-auto">
                            <div className="section-title">
                                <h2>সেবা প্রদানকারী ব্যক্তিগন</h2>
                                <p>এই প্রতিষ্ঠানটিকে সাফল্যমন্ডিত করতে ও দরবারে এলাহিতে মাকবুলিয়্যাতের লক্ষ্যে যারা নিরলস শ্রম ও প্রচেষ্টা অব্যহত রেখেছেন</p>
                                <div className="tab">
                                    <span className="tablinks btn btn-outline-success mx-2 active"
                                        onClick={(e) => { Team_vissible(e, 'all') }}>সব</span>
                                    <span className="tablinks btn btn-outline-success mx-2 "
                                        onClick={(e) => { Team_vissible(e, 'advisoryBorde') }}>উপদেষ্টামন্ডলী</span>
                                    <span className="tablinks btn btn-outline-success mx-2"
                                        onClick={(e) => { Team_vissible(e, 'official') }}>কর্মকর্তা</span>
                                    <span className="tablinks btn btn-outline-success mx-2"
                                        onClick={(e) => { Team_vissible(e, 'teacher') }}>শিক্ষক</span>
                                    <span className="tablinks btn btn-outline-success mx-2"
                                        onClick={(e) => { Team_vissible(e, 'employee') }}>কর্মচারী</span>
                                </div >
                            </div >
                        </div >

                        <div className='col-12 col-md-12 mx-auto'>
                            <div id={"all"} className={"team_member_by_catagory d-block"}>
                                <div className="row ">
                                    {team_employees_catagory_1 !== undefined && team_employees_catagory_1 !== null && team_employees_catagory_1.length > 0 && (team_employees_catagory_1.map((data1) => (<>
                                        {data1.designations_devide.length > 0 && (data1.designations_devide.map((data2) => (
                                            <div className="col-7 col-sm-6 mx-auto col-md-5 col-lg-3 my-4">
                                                <div className="single-team">
                                                    <div className="team-image height_adjust" style={{}}>
                                                        <img style={{ minWidth: "100%", minHeight: "100%" }} src={(data2.photo !== null && data2.photo.length > 0 ? (`${multi_image_url}/uploads/employee-photo/${data2.photo}`) : (Team_1))}
                                                            alt="" />
                                                    </div>
                                                    <div className="team-member-details">
                                                        <h4>{data2.name}</h4>
                                                        <p>{data1.name}</p>
                                                        <Link to={`/Manage_profile/${data2.user_id}`}>সম্পূর্ণ প্রোফাইল</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        )))}
                                    </>
                                    )))}
                                    {team_employees_catagory_2 !== undefined && team_employees_catagory_2 !== null && team_employees_catagory_2.length > 0 && (team_employees_catagory_2.map((data1) => (<>
                                        {data1.designations_devide.length > 0 && (data1.designations_devide.map((data2) => (
                                            <div className="col-7 col-sm-6 mx-auto col-md-5 col-lg-3 my-4">
                                                <div className="single-team">
                                                    <div className="team-image height_adjust" style={{}}>
                                                        <img style={{ minWidth: "100%", minHeight: "100%" }} src={(data2.photo !== null && data2.photo.length > 0 ? (`${multi_image_url}/uploads/employee-photo/${data2.photo}`) : (Team_1))}
                                                            alt="" />
                                                    </div>
                                                    <div className="team-member-details">
                                                        <h4>{data2.name}</h4>
                                                        <p>{data1.name}</p>
                                                        <Link to={`/Manage_profile/${data2.user_id}`}>সম্পূর্ণ প্রোফাইল</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        )))}
                                    </>
                                    )))}
                                    {team_employees_catagory_3 !== undefined && team_employees_catagory_3 !== null && team_employees_catagory_3.length > 0 && (team_employees_catagory_3.map((data1) => (<>
                                        {data1.designations_devide.length > 0 && (data1.designations_devide.map((data2) => (
                                            <div className="col-7 col-sm-6 mx-auto col-md-5 col-lg-3 my-4">
                                                <div className="single-team">
                                                    <div className="team-image height_adjust" style={{}}>
                                                        <img style={{ minWidth: "100%", minHeight: "100%" }} src={(data2.photo !== null && data2.photo.length > 0 ? (`${multi_image_url}/uploads/employee-photo/${data2.photo}`) : (Team_1))}
                                                            alt="" />
                                                    </div>
                                                    <div className="team-member-details">
                                                        <h4>{data2.name}</h4>
                                                        <p>{data1.name}</p>
                                                        <Link to={`/Manage_profile/${data2.user_id}`}>সম্পূর্ণ প্রোফাইল</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        )))}
                                    </>
                                    )))}
                                    {team_teachers !== undefined && team_teachers !== null && team_teachers.length > 0 && (team_teachers.map((data) => (
                                        <div className="col-7 col-sm-6 mx-auto col-md-5 col-lg-3 my-4">
                                            <div className="single-team">
                                                <div className="team-image height_adjust" style={{}}>
                                                    <img style={{ minWidth: "100%", minHeight: "100%" }} src={(data.photo !== null && data.photo.length > 0 ? (`${multi_image_url}/uploads/teacher-photo/${data.photo}`) : (Team_1))}
                                                        alt="" />
                                                </div>
                                                <div className="team-member-details">
                                                    <h4>{data.name}</h4>
                                                    <p>শিক্ষক</p>
                                                    <Link to={`/Manage_profile/${data.user_id}`}>সম্পূর্ণ প্রোফাইল</Link>
                                                </div>
                                            </div>
                                        </div>)))}
                                    {len && (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                </div>
                            </div>
                            <div id={"advisoryBorde"} className={"team_member_by_catagory d-none"}>
                                <div className="row ">
                                    {updatemptyteam(0, false)}
                                    {team_employees_catagory_1 !== undefined && team_employees_catagory_1 !== null && team_employees_catagory_1.length > 0 ? (team_employees_catagory_1.map((data1) => (<>
                                        {data1.designations_devide.length > 0 && (data1.designations_devide.map((data2) => (
                                            <div className="col-7 col-sm-6 mx-auto col-md-5 col-lg-3 my-4">
                                                <div className="single-team">
                                                    <div className="team-image height_adjust" style={{}}>
                                                        <img style={{ minWidth: "100%", minHeight: "100%" }} src={(data2.photo !== null && data2.photo.length > 0 ? (`${multi_image_url}/uploads/employee-photo/${data2.photo}`) : (Team_1))}
                                                            alt="" />
                                                    </div>
                                                    <div className="team-member-details">
                                                        <h4>{data2.name}</h4>
                                                        <p>{data1.name}</p>
                                                        <Link to={`/Manage_profile/${data2.user_id}`}>সম্পূর্ণ প্রোফাইল</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        )))}
                                        {data1.designations_devide.length == 0 && updatemptyteam('', true)}
                                        {empty_team.current == team_employees_catagory_1.length && (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                    </>
                                    ))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                </div>
                            </div>
                            <div id={"official"} className={"team_member_by_catagory d-none"}>

                                <div className="row ">
                                    {updatemptyteam(0, false)}
                                    {team_employees_catagory_2 !== undefined && team_employees_catagory_2 !== null && team_employees_catagory_2.length > 0 ? (team_employees_catagory_2.map((data1) => (<>
                                        {data1.designations_devide.length > 0 && (data1.designations_devide.map((data2) => (
                                            <div className="col-7 col-sm-6 mx-auto col-md-5 col-lg-3 my-4">
                                                <div className="single-team">
                                                    <div className="team-image height_adjust" style={{}}>
                                                        <img style={{ minWidth: "100%", minHeight: "100%" }} src={(data2.photo !== null && data2.photo.length > 0 ? (`${multi_image_url}/uploads/employee-photo/${data2.photo}`) : (Team_1))}
                                                            alt="" />
                                                    </div>
                                                    <div className="team-member-details">
                                                        <h4>{data2.name}</h4>
                                                        <p>{data1.name}</p>
                                                        <Link to={`/Manage_profile/${data2.user_id}`}>সম্পূর্ণ প্রোফাইল</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        )))}
                                        {data1.designations_devide.length == 0 && updatemptyteam('', true)}
                                        {empty_team.current == team_employees_catagory_2.length && (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                    </>
                                    ))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                </div>
                            </div>
                            <div id={"teacher"} className={"team_member_by_catagory d-none"}>
                                <div className="row ">
                                    {team_teachers !== undefined && team_teachers !== null ? (team_teachers.map((data) => (
                                        <div className="col-7 col-sm-6 mx-auto col-md-5 col-lg-3 my-4">
                                            <div className="single-team">
                                                <div className="team-image height_adjust" style={{}}>
                                                    <img style={{ minWidth: "100%", minHeight: "100%" }} src={(data.photo !== null && data.photo.length > 0 ? (`${multi_image_url}/uploads/teacher-photo/${data.photo}`) : (Team_1))}
                                                        alt="" />
                                                </div>
                                                <div className="team-member-details">
                                                    <h4>{data.name}</h4>
                                                    <p>শিক্ষক</p>
                                                    <Link to={`/Manage_profile/${data.user_id}`}>সম্পূর্ণ প্রোফাইল</Link>
                                                </div>
                                            </div>
                                        </div>))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                </div>
                            </div>
                            <div id={"employee"} className={"team_member_by_catagory d-none"}>

                                <div className="row ">
                                {updatemptyteam(0,false)}
                                    {team_employees_catagory_3 !== undefined && team_employees_catagory_3 !== null && team_employees_catagory_3.length > 0 ? (team_employees_catagory_3.map((data1) => (<>
                                        {data1.designations_devide.length > 0 && (data1.designations_devide.map((data2) => (
                                            <div className="col-7 col-sm-6 mx-auto col-md-5 col-lg-3 my-4">
                                                <div className="single-team">
                                                    <div className="team-image height_adjust" style={{}}>
                                                        <img style={{ minWidth: "100%", minHeight: "100%" }} src={(data2.photo !== null && data2.photo.length > 0 ? (`${multi_image_url}/uploads/employee-photo/${data2.photo}`) : (Team_1))}
                                                            alt="" />
                                                    </div>
                                                    <div className="team-member-details">
                                                        <h4>{data2.name}</h4>
                                                        <p>{data1.name}</p>
                                                        <Link to={`/Manage_profile/${data2.user_id}`}>সম্পূর্ণ প্রোফাইল</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))) }
                                        {data1.designations_devide.length ==0 && updatemptyteam('',true)}
                                        {empty_team.current == team_employees_catagory_3.length && (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                    </>
                                    ))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                </div>
                            </div>


                            {/* <div className={"team_member_by_catagory d-block"} id="advisoryBorde">
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={20}
                                    grabCursor={true}
                                    loop={true}
                                    pagination={{
                                        el: '.swiper-pagination',
                                        clickable: true,
                                        renderBullet: function (index, className) {
                                            return '<span class="' + className + ' my_sigle_bullet_color_1"></span>';
                                        },
                                    }}
                                    modules={[Pagination]}
                                    breakpoints={{

                                        640: {
                                            slidesPerView: 2,
                                        },


                                        768: {
                                            slidesPerView: 3,
                                        },
                                        1200: {
                                            slidesPerView: 4
                                        }
                                    }}
                                    className="mySwiper">
                                    {team_employees_catagory_1 !== undefined && team_employees_catagory_1 !== null && team_employees_catagory_1.length > 0 ? (team_employees_catagory_1.map((data1) => (<>
                                        {data1.designations_devide.length > 0 ? (data1.designations_devide.map((data2) => (
                                            <SwiperSlide style={{ paddingBottom: "3rem" }} >
                                                <div className="single-team">
                                                    <div className="team-image">
                                                        <img src={Team_1}
                                                            alt="" />
                                                    </div>
                                                    <div className="team-member-details">
                                                        <h4>{data2.name}</h4>
                                                        <p>{data1.name}</p>
                                                        <Link to={`/Manage_profile/${data2.user_id}`}>সম্পূর্ণ প্রোফাইল</Link>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                    </>
                                    ))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}


                                    <div className="swiper-pagination"></div>
                                </Swiper>
                            </div>
                            <div className={"team_member_by_catagory d-none"} id="official">
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={20}
                                    grabCursor={true}
                                    loop={true}
                                    pagination={{
                                        el: '.swiper-pagination',
                                        clickable: true,
                                        renderBullet: function (index, className) {
                                            return '<span class="' + className + ' my_sigle_bullet_color_1"></span>';
                                        },
                                    }}
                                    modules={[Pagination]}
                                    breakpoints={{

                                        640: {
                                            slidesPerView: 2,
                                        },


                                        768: {
                                            slidesPerView: 3,
                                        },
                                        1200: {
                                            slidesPerView: 4
                                        }
                                    }}
                                    className="mySwiper">
                                    {team_employees_catagory_2 !== undefined && team_employees_catagory_2 !== null && team_employees_catagory_2.length > 0 ? (team_employees_catagory_2.map((data1) => (<>
                                        {data1.designations_devide.length > 0 ? (data1.designations_devide.map((data2) => (
                                            <SwiperSlide style={{ paddingBottom: "3rem" }} >
                                                <div className="single-team">
                                                    <div className="team-image">
                                                        <img src={Team_1}
                                                            alt="" />
                                                    </div>
                                                    <div className="team-member-details">
                                                        <h4>{data2.name}</h4>
                                                        <p>{data1.name}</p>
                                                        <Link to={`/Manage_profile/${data2.user_id}`}>সম্পূর্ণ প্রোফাইল</Link>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                    </>
                                    ))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}


                                    <div className="swiper-pagination"></div>
                                </Swiper>
                            </div>
                            <div className={"team_member_by_catagory d-none"} id="teacher">
                                <Swiper
                                    slidesPerView={1}
                                    // slidesPerView={'auto'}
                                    spaceBetween={20}
                                    grabCursor={true}
                                    loop={true}

                                    pagination={{
                                        el: '.swiper-pagination',
                                        clickable: true,
                                        renderBullet: function (index, className) {
                                            return '<span class="' + className + ' my_sigle_bullet_color_1"></span>';
                                        },
                                    }}
                                    modules={[Pagination]}
                                    breakpoints={{

                                        640: {
                                            slidesPerView: 2,
                                        },


                                        768: {
                                            slidesPerView: 3,
                                        },
                                        1200: {
                                            slidesPerView: 4
                                        }
                                    }}
                                    className="mySwiper">
                                    {team_teachers !== undefined && team_teachers !== null ? (team_teachers.map((data) => (
                                        <SwiperSlide style={{ paddingBottom: "3rem" }} >
                                            <div className="single-team">
                                                <div className="team-image">
                                                    <img src={Team_1}
                                                        alt="" />
                                                </div>
                                                <div className="team-member-details">
                                                    <h4>{data.name}</h4>
                                                    <p>শিক্ষক</p>
                                                    <Link to={`/Manage_profile/${data.user_id}`}>সম্পূর্ণ প্রোফাইল</Link>
                                                </div>
                                            </div>
                                        </SwiperSlide>))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}

                                    <div className="swiper-pagination"></div>
                                </Swiper>
                            </div>
                            <div className={"team_member_by_catagory d-none "} id="employee">
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={20}
                                    grabCursor={true}
                                    loop={true}
                                    pagination={{
                                        el: '.swiper-pagination',
                                        clickable: true,
                                        renderBullet: function (index, className) {
                                            return '<span class="' + className + ' my_sigle_bullet_color_1"></span>';
                                        },
                                    }}
                                    modules={[Pagination]}
                                    breakpoints={{

                                        640: {
                                            slidesPerView: 2,
                                        },


                                        768: {
                                            slidesPerView: 3,
                                        },
                                        1200: {
                                            slidesPerView: 4
                                        }
                                    }}
                                    className="mySwiper">
                                    {team_employees_catagory_3 !== undefined && team_employees_catagory_3 !== null && team_employees_catagory_3.length > 0 ? (team_employees_catagory_3.map((data1) => (<>
                                        {data1.designations_devide.length > 0 ? (data1.designations_devide.map((data2) => (
                                            <SwiperSlide style={{ paddingBottom: "3rem" }} >
                                                <div className="single-team">
                                                    <div className="team-image">
                                                        <img src={Team_1}
                                                            alt="" />
                                                    </div>
                                                    <div className="team-member-details">
                                                        <h4>{data2.name}</h4>
                                                        <p>{data1.name}</p>
                                                        <Link to={`/Manage_profile/${data2.user_id}`}>সম্পূর্ণ প্রোফাইল</Link>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                    </>
                                    ))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}


                                    <div className="swiper-pagination"></div>
                                </Swiper>
                            </div> */}


                            {/* <div className="tabcontent" id="advisoryBorde">
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={20}
                                    grabCursor={true}
                                    loop={true}
                                    pagination={{
                                        el: '.swiper-pagination',
                                        clickable: true,
                                        renderBullet: function (index, className) {
                                            return '<span class="' + className + ' my_sigle_bullet_color_1"></span>';
                                        },
                                    }}
                                    modules={[Pagination]}
                                    breakpoints={{
                                        
                                        640: {
                                            slidesPerView: 2,
                                        },

                                       
                                        768: {
                                            slidesPerView: 3,
                                        },
                                        1200: {
                                            slidesPerView: 4
                                        }
                                    }}
                                    className="mySwiper">
                                    {Object.keys(dataTeam_advisoryBorde).map((key2) => (

                                        <SwiperSlide style={{ paddingBottom: "3rem" }} >

                                            <div className="single-team">
                                                <div className="team-image">
                                                    <img src={dataTeam_advisoryBorde[key2].single_link}
                                                        alt="" />
                                                </div>
                                                <div className="team-member-details">
                                                    <h4>{dataTeam_advisoryBorde[key2].title}</h4>
                                                    <p>{dataTeam_advisoryBorde[key2].short_description}</p>
                                                    <Link to={'/Manage_profile'}>{dataTeam_advisoryBorde[key2].long_description}</Link>
                                                </div>
                                            </div>
                                        </SwiperSlide>

                                    ))}

                                 
                                    <div className="swiper-pagination"></div>
                                </Swiper>
                            </div> */}

                        </div>
                    </div>
                </div >
            </section >

            {/* <section id='All_Employee'>
                <div className="container">
                    <div className="" style={{ margin: '5% 0' }}>
                        <div className="section-title">
                            <h2>{data_employee_title.length > 0 ? data_employee_title[0].title : "get_employee_title()"}</h2>
                            <p>{data_employee_title.length > 0 ? data_employee_title[0].short_description : "get_employee_title()"}</p>
                            <div className="tab">
                                <span className="tablinks btn btn-outline-success mx-2 active"
                                    onClick={(e) => { Team_vissible(e, 'worker') }}>All</span>
                                <span className="tablinks btn btn-outline-success mx-2 "
                                    onClick={(e) => { Team_vissible(e, 'advisoryBorde') }}>উপদেষ্টামন্ডলী</span>
                                <span className="tablinks btn btn-outline-success mx-2"
                                    onClick={(e) => { Team_vissible(e, 'official') }}>কর্মকর্তা</span>
                                <span className="tablinks btn btn-outline-success mx-2"
                                    onClick={(e) => { Team_vissible(e, 'teacher') }}>শিক্ষক</span>
                                <span className="tablinks btn btn-outline-success mx-2"
                                    onClick={(e) => { Team_vissible(e, 'employee') }}>কর্মচারী</span>
                            </div >
                        </div >

                        {Catagory_data_list.map((data) => (
                            <div id={data} className={"team_member_by_catagory " + (data == 'worker' ? 'd-block' : 'd-none')}>
                                <div className="row ">
                                    {data_all_employee.length > 0 ? data_all_employee.map((data) => (
                                        <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                            <div className="single-team">
                                                <div className="team-image">
                                                    <img src={data.profile_image_url}
                                                        alt="" />
                                                </div>
                                                <div className="team-member-details">
                                                    <h4>{data.name}</h4>
                                                    <p>{data.profession}</p>
                                                    <Link to={'/Manage_profile/' + data.id}>সম্পূর্ণ প্রোফাইল</Link>
                                                </div>
                                            </div>
                                        </div>
                                    )) : ''}
                                </div>
                            </div>
                        ))}
                        <div id='advisoryBorde' className="team_member_by_catagory d-none">
                            <div className="row ">
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id='official' className="team_member_by_catagory d-none">
                            <div className="row ">
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div id='teacher' className="team_member_by_catagory d-none">
                            <div className="row ">
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div id='employee' className="team_member_by_catagory d-none">
                            <div className="row ">
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    )
}
