import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import dataContext from '../context/data/dataContext';

export default function AboutUs() {
    const context = useContext(dataContext);
    const { data, multi_image_url } = context

    const windoSCrollCount = useRef(0)
    useEffect(() => {
        if (windoSCrollCount.current === 0) {
            // console.log('asdfasfsa')
            window.scrollTo({ top: 0, behavior: "smooth" })
            windoSCrollCount.current = 1
        }
        let scrolling = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop


    }, [])

    var about_text, about_image;
    if (data.length > 0) {
        var { about_text, about_image } = data[0];
    }

    return (
        <>
            <section id="page-banner">
                <div className="banner-inner"
                    style={{ backgroundImage: `url(https://source.unsplash.com/random/1600x200/?islamic)` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">

                                <div className="banner-contents">
                                    <h1 className='d-block'> মাদরাসা সম্পর্কে</h1>
                                    <nav className="breadcrumbs " style={{ opacity: "1" }}>
                                        <Link to={'/'} className="breadcrumbs__item"><i className="text-black fa-solid fa-house"></i> হোম</Link>
                                        {/* <Link to={'/'} className="breadcrumbs__item">Shop</Link> */}
                                        {/* <Link to={'/'} className="breadcrumbs__item">Cart</Link> */}
                                        <Link to={'/aboutUs'} className="breadcrumbs__item is-active"> মাদরাসা সম্পর্কে</Link>
                                    </nav>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="history" >
                <div className="container" >
                    <h3 className='mt-5 text-center'></h3>

                    {/* {about_image !== undefined && about_image !== null && about_image.length > 0 &&
                        <div style={{float:"left", height:"fit-content", width:"fit-content"}}>
                            <img className=' ' style={{borderRadius: "5%", height:"18rem",width:"31rem",paddingRight:"1rem", paddingBottom:"1rem" }} src={`${multi_image_url}/uploads/about/${about_image}`} alt="" />
                        </div>
                    }
                    {about_text !== undefined && about_text !== null && about_text.length > 0 &&
                        <p className='my-5' style={{ textAlign: "justify" }}>{about_text}</p>
                    } */}
                    <div style={{ display: "inline-block", marginBottom: "3rem" }}>
                        <div>
                            {about_image !== undefined && about_image !== null && about_image.length > 0 &&
                                <img className=' ' style={{ borderRadius: "5%", float: "left", height: "18rem", width: "31rem", paddingRight: "1rem", paddingBottom: "0rem" }} src={`${multi_image_url}/uploads/about/${about_image}`} alt="" />
                            }
                            
                            {about_text !== undefined && about_text !== null && about_text.length > 0 &&
                                <p className='my-5' style={{ textAlign: "justify" }}>{about_text}</p>
                            }

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}
