import React, { useEffect, useState, useRef, useContext } from 'react'
import Logo from "../assets/images/logo.png";
import Down_Arrow from "../assets/images/down arrow.svg";
import { Link, json } from 'react-router-dom';
import Http from "../Http_Api";
import dataContext from '../context/data/dataContext';
// import default_color from "../custom_css/default_color.css";

export default function Navbar() {
    const [scroll, setScroll] = useState(0);
    const [school_title, setSchool_title] = useState('');
    const Myscroll = useRef(0)
    const context = useContext(dataContext);
    const { multi_image_url, url, data, getData, } = context;
    const [getAllData, setGetAllData] = useState([]);

    const themestate = useRef({
        primaryColor: "#156c42",
        primaryLightColor: "#1ea362",
        secondaryColor: "#767676",
        black1: "#222",
        white1: "white",
        white2: "#e3f1fb",
        cream1: "#e4d296"
    })

    useEffect(() => {
        if (start_fetch_data.current) {
            change_theme_fun();
        }
        import(`../default_color_css/main_default_color.css`);
        import(`../default_color_css/style_default_color.css`);
        import(`../default_color_css/new_style_default_color.css`);
        import(`../default_color_css/swiper_bullet_default_color.css`);


        // if (school_name !== undefined && school_name !== null) {
        //     if (school_name.length > 0) {
        //         document.title = 'this is mahi';
        //         console.log(school_name)
        //     }
        // }

        window.addEventListener('scroll', while_user_scroll);
        return () => {
            window.removeEventListener('scholl', while_user_scroll);
        }
    }, [])

    const start_fetch_data = useRef(true);
    const all_data = useRef(null);
    const change_theme_fun = async () => {

        let mydata = await getData();
        console.log(multi_image_url+Math.random(20))
        if (mydata.length > 0) {
            // console.log(mydata[0].theme[0]);
            if (mydata[0].theme.length >0) {
                if (mydata[0].theme[0].primary_color && mydata[0].theme[0].primary_color !=='') {
                    document.documentElement.style.setProperty('--Site-primary-color', mydata[0].theme[0].primary_color)
                }
                if (mydata[0].theme[0].secondary_color && mydata[0].theme[0].secondary_color !=='') {
                    document.documentElement.style.setProperty('--bg-gray-2', mydata[0].theme[0].secondary_color)
                }
                if (mydata[0].theme[0].primary_light_color && mydata[0].theme[0].primary_light_color !=='') {
                    document.documentElement.style.setProperty('--Primary-light', mydata[0].theme[0].primary_light_color)
                }
                if (mydata[0].theme[0].primary_lighter_color && mydata[0].theme[0].primary_lighter_color !=='') {
                    document.documentElement.style.setProperty('--bg-green-1', mydata[0].theme[0].primary_lighter_color)
                }
              
            }
            start_fetch_data.current = false
            await change_fav_icon(mydata[0])
            console.log("you can change the theem");
        }

    }



    const change_fav_icon = async () => {
        if (data.length > 0) {
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            if (data[0].header_top !== undefined && data[0].header_top !== null) {
                if (data[0].header_top.length === 1) {
                    await (data[0].header_top.map((data) => {
                        link.href = `${multi_image_url}/uploads/headertop/${data.fav_icon}`;
                    }))
                }
                else {
                    link.href = 'https://eduself.webshinez.top/assets/uploads//logo/1679485868-favicon-icon.png';
                }
            }
            else {
                link.href = 'https://eduself.webshinez.top/assets/uploads//logo/1679485868-favicon-icon.png';
            }
        }
    }


    var header_top, school_name, email, address, facebook_url, facebook_url, phone, linkedin_url, google_map, google_plus_url, twitter_url, instagram_url, frontend_logo, speech, theme;

    if (data.length > 0) {
        var { header_top, school_name, email, address, facebook_url, facebook_url, phone, linkedin_url, google_map, google_plus_url, twitter_url, instagram_url, frontend_logo, speech, theme } = data[0];


        document.title = school_name
        change_fav_icon();
        // console.log((data))
    }



    // var xmlHttp = new XMLHttpRequest();
    // xmlHttp.open( "GET", `https://multidomain.webshinez.top/api/madrasha.webshinez.top`, false ); // false for synchronous request
    // xmlHttp.send( null );
    // console.log(JSON.parse(xmlHttp.response))
    // console.log(multi_image_url)





    const while_user_scroll = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop
        Myscroll.current = winScroll;
        header_top_visiblity();
        setScroll(winScroll)
    }

    const header_top_visiblity = () => {
        let header_top = document.getElementsByClassName('header-top')[0];
        let header = document.getElementsByTagName('header')[0];
        if (Myscroll.current > 150) {
            header.classList.add('sticky');
        }
        if (Myscroll.current < 150) {
            header.classList.remove('sticky');
        }
    }
    return (
        <>
            <header>
                {header_top !== undefined && header_top !== null &&
                    (header_top.length === 1 &&
                        (header_top.map((data) =>
                            <>
                                {data.is_view_on_web === 1 &&
                                    <div className="header-top " >
                                        {data.style === "0" &&
                                            <div className="container ">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="top-notice">
                                                            <ul>
                                                                {data.first_short_text_display === 1 &&
                                                                    <li> <i className={data.first_short_text_icon.length > 0 ? (data.first_short_text_icon) : "far fa-clock"}></i>{data.first_short_text.length > 0 && (data.first_short_text.slice(0, 75))}</li>}
                                                                {data.second_short_text_display === 1 &&
                                                                    <li> <i className={data.second_short_text_icon.length > 0 ? (data.second_short_text_icon) : "fa fa-mobile-alt"}></i>{data.second_short_text.length > 0 && (data.second_short_text.slice(0, 45))}</li>}
                                                                {data.third_short_text_display === 1 &&
                                                                    <li> <i className={data.third_short_text_icon.length > 0 ? (data.third_short_text_icon) : "fa fa-envelope"}></i>{data.third_short_text.length > 0 && (data.third_short_text.slice(0, 45))}</li>}



                                                                <li className="mulitlang" style={{ visibility: "hidden" }}><a href="#">English</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {data.style === "1" &&
                                            <marquee behavior="scroll" direction="left" width="100%">
                                                <div className="container ">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="top-notice">
                                                                <ul>
                                                                    {data.long_text_display === 1 &&
                                                                        (<>
                                                                            <>
                                                                                {
                                                                                    typeof data.long_text === "string" &&
                                                                                    <li> <i className={data.long_text_icon.length > 0 ? (data.long_text_icon) : "far fa-clock"}></i>{data.long_text.length > 0 && (data.long_text)}</li>
                                                                                }
                                                                            </>
                                                                            <>
                                                                                {
                                                                                    typeof data.long_text === "object" && (
                                                                                        <>
                                                                                            {data.long_text !== undefined && data.long_text !== null && data.long_text.length > 0 && data.long_text.map((data2 => (
                                                                                                <li> <i className={data.long_text_icon.length > 0 ? (data.long_text_icon) : "far fa-clock"}></i>{data2.title.length > 0 && (data2.title)}</li>
                                                                                            )))}
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        </>
                                                                        )}
                                                                    <li className="mulitlang " style={{ visibility: "hidden" }}><a href="#" >English</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </marquee>
                                        }
                                    </div >
                                }
                            </>
                        )
                        )
                    )
                }
                <div className="header-center-inner">
                    <div className="container" >
                        <div className="row">
                            <div className="col-12">
                                <div className="header-center">
                                    {frontend_logo !== undefined && frontend_logo !== null ? (
                                        <div className="logo mx-auto ">
                                            <Link to="/">
                                                <img style={{ height: "3.5rem" }} src={`${multi_image_url}/uploads/logo/${frontend_logo}`}
                                                    alt="" />
                                            </Link>
                                        </div>) : null}
                                    <div className="header-center-contacts header_hide_in_Mobile">
                                        {phone !== undefined && phone !== null ? (<div className="single-header-contact">
                                            <i className="fa fa-phone-alt"></i>
                                            <div className="header-contents">
                                                <p><a className='' href={"tel: +88" + (phone)}>{phone}</a></p>
                                                <p>আমাদের সাথে যোগাযোগ</p>
                                            </div>
                                        </div>) : null}
                                        {address !== undefined && address !== null ? (<div className="single-header-contact header_hide_in_Mobile">
                                            <i className="fa fa-map-marker-alt"></i>
                                            <div className="header-contents">
                                                <a href={google_map} target='_blank' >
                                                    <p>{address}</p>
                                                    <p></p>
                                                </a>
                                            </div>
                                        </div>) : null}
                                        <div className="socials">
                                            <ul>
                                                {facebook_url !== undefined && facebook_url !== null && facebook_url.length > 0 ? (<li className='mx-1'><a href={facebook_url}><i className="fab fa-facebook-f"></i></a></li>) : null}
                                                {twitter_url !== undefined && twitter_url !== null && twitter_url.length > 0 ? (<li className='mx-1'><a href={twitter_url}><i className="fab fa-twitter"></i></a></li>) : null}
                                                {linkedin_url !== undefined && linkedin_url !== null && linkedin_url.length > 0 ? (<li className='mx-1'><a href={linkedin_url}><i className="fab fa-linkedin-in"></i></a></li>) : null}
                                                {instagram_url !== undefined && instagram_url !== null && instagram_url.length > 0 ? (<li className='mx-1'><a href={instagram_url}><i className="fab fa-instagram"></i></a></li>) : null}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-main-inner" >
                    <div className="container" style={{ padding: " 0 5rem " }}>
                        <div className="row">
                            <div className="col-12" >
                                <div className="header-main">
                                    <div className="main-menu-wrapper">
                                        <div className="nav-header">
                                            <div className="hamburger"></div>
                                        </div>
                                        <div className="collapse-nav-inner">
                                            <div className="menu-trigger"></div>
                                            <div className="collapse-nav ">
                                                <div className="nav-header">
                                                    <div className="hamburger"></div>
                                                </div>
                                                <div className="main-menu  ">
                                                    <ul>
                                                        <li className="active_link"><Link to={'/'}>হোম</Link></li>
                                                        <li className="menu"><a href="#">আমাদের সম্পর্কে
                                                            <i class="" ></i>
                                                            <i class="fa fa-caret-down" aria-hidden="true" style={{ marginLeft: "0.5rem" }}></i></a>
                                                            <div className='anti_menu'>
                                                                <ul className="">
                                                                    <li><Link to={"/aboutUs"}>মাদরাসা সম্পর্কে</Link></li>
                                                                    <li><Link to={"/history"}>ইতিহাস</Link></li>

                                                                    <li><Link to={"/target"}>লক্ষ্য</Link></li>
                                                                    <li><Link to={"/employee"}>সেবা প্রদানকারী ব্যক্তিগন</Link></li>
                                                                    <li className="sub_menu"><a href="#">বাণী
                                                                        <i class="fa fa-caret-down" aria-hidden="true" style={{ marginLeft: "0.5rem" }}></i></a>
                                                                        {speech !== undefined && speech !== null &&
                                                                            (
                                                                                <>
                                                                                    <div className="sub_anti_menu">
                                                                                        <ul className="">
                                                                                            {/* <li><Link to={"/Chief_Director"}>প্রধান পরিচালকের</Link></li> */}

                                                                                            {/* <li><Link to={"/Executive_Director"}>নির্বাহী পরিচালকের</Link></li> */}
                                                                                            {speech.map((data) => (
                                                                                                <li><Link to={`/speech/${data.id}`}>{data.message_type}</Link></li>
                                                                                            ))}
                                                                                        </ul>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li className="menu"><a href="#">ঘোষণা
                                                            <i class="fa fa-caret-down" aria-hidden="true" style={{ marginLeft: "0.5rem" }}></i></a>
                                                            <div className="anti_menu">
                                                                <ul className="">
                                                                    <li><Link to={"/NoticeBorde"}>নোটিশ</Link></li>

                                                                    <li><Link to={"/EventAll"}>ইভেন্ট</Link></li>

                                                                    <li><Link to={"/NewsAll"}>নিউজ</Link></li>

                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li className=""><Link to="/gallary">গ্যালারি</Link></li>
                                                        <li className=""><Link to="/contactUs">যোগাযোগ</Link></li>

                                                    </ul>


                                                    <div style={{ display: "none" }} className="donation  mx-4 mt-5 hide_in_pc same_height_width" >
                                                        <Link to="/donation">অনুদান</Link>
                                                    </div>
                                                    <div className=" My_menu_search_PC_hide same_height_width mx-4 ">
                                                        <input type="text"
                                                            placeholder="অনুসন্ধান" />
                                                        <i className="fas fa-search" ></i>
                                                    </div>
                                                </div>
                                                <div className='contact_menu_mob hide_in_pc text-white mx-3 mb-3'>
                                                    <ul className='d-flex flex-column '>
                                                        <li className='d-flex  '>
                                                            <div className="single-header-contact" style={{ width: "100%" }}>
                                                                <i className="fa fa-phone-alt"></i>
                                                                <div className="header-contents">
                                                                    <p><a href="tel: +8801933794616">(+৮৮) ০১৯৩৩-৭৯৪৬১৬</a></p>
                                                                    <p>আমাদের সাথে যোগাযোগ</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className='d-flex  my-4'>
                                                            <div className="single-header-contact" style={{ width: "100%" }}>
                                                                <i className="fa fa-map-marker-alt" style={{ width: "3rem" }}></i>
                                                                <div className="header-contents">
                                                                    <p className='text-white'><a href="https://maps.app.goo.gl/2PUxQ9AbWH2JKsWx5" >রশিদপুর, জামালপুর সদর, জামালপুর - ২০০০</a></p>

                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="extra-menu ">
                                                <div className="menu-search hide_in_Mobile mx-3">
                                                    <input type="text"
                                                        placeholder="অনুসন্ধান" />
                                                    <i className="fas fa-search" ></i>
                                                </div>
                                                <div style={{ display: "none" }} className="donation hide_in_Mobile">
                                                    <Link to="/donation">অনুদান</Link>
                                                </div>
                                                <div className="donation mx-3" style={{}}>
                                                    <Link target='_blank' to="https://qems.eduself.co/auth/login">Login</Link>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header >
            <spacer></spacer>
        </>
    )
}
