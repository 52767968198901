import React, { useContext, useEffect, useRef, useState } from 'react'
import Teacher from "../assets/images/teacher.jpg"
import Contact from '../assets/images/contact.jpg';
import Team_1 from '../assets/images/team1.png'
import '../custom_css/Template.css'
import { Link } from 'react-router-dom';
import Http from '../Http_Api';
import { useParams } from 'react-router-dom';
import dataContext from '../context/data/dataContext';
import Http_Api from '../Http_Api';
export default function Manage_profile_1() {
    const [dataUser, setDataUser] = useState([]);
    const { id } = useParams();
    const windoSCrollCount = useRef(0)
    const context = useContext(dataContext);
    const { users_data, getUserDataByUserId, multi_image_url } = context;

    useEffect(() => {
        getUserDataByUserId(id)
        if (windoSCrollCount.current === 0) {
            window.scrollTo({ top: 0, behavior: "smooth" })
            windoSCrollCount.current = 1
        }
        let scrolling = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop
    }, [])


    var user;
    if (users_data.length >= 1) {
        // console.log(users_data)
        // console.log('asdfasfsa')

        var { user, user_type } = users_data[0];
    }

    if (user !== undefined) {
        let val1 = [];
        user.map((data) => {
            val1.push(data);
        })
        user = val1;
        // console.log(user);
    }

    return (
        <>
            <section id="page-banner">
                <div className="banner-inner"

                    style={{ backgroundImage: `url(https://source.unsplash.com/random/1600x200/?islamic)` }}>

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="banner-contents">
                                    <h1 className='d-block'>সম্পূর্ণ প্রোফাইল</h1>
                                    <nav className="breadcrumbs " style={{ opacity: "1" }}>
                                        <Link to={'/'} className="breadcrumbs__item"><i className="text-black fa-solid fa-house"></i> হোম</Link>
                                        {/* <Link to={'/'} className="breadcrumbs__item">Shop</Link> */}
                                        <Link to={'/employee'} className="breadcrumbs__item">সেবা প্রদানকারী ব্যক্তিগন</Link>
                                        <Link to={`/Manage_profile/${id}`} className="breadcrumbs__item is-active">সম্পূর্ণ প্রোফাইল</Link>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='Profile_view'>
                <div className="container">
                    {user !== undefined && user.length > 0 ? (user.map((data) => (
                        <div class="card height-auto" style={{ border: "none" }}>
                            <div class="card-body">
                                <div class="heading-layout1">
                                    <div class="item-title mx-auto my-3">
                                        <h3>প্রোফাইল</h3>
                                    </div>
                                    {/* <div class="dropdown">
                                        <a class="dropdown-toggle" href="#" role="button"
                                            data-toggle="dropdown" aria-expanded="false">...</a>

                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item" href="#"><i class="fas fa-times text-orange-red"></i>Close</a>
                                            <a class="dropdown-item" href="#"><i class="fas fa-cogs text-dark-pastel-green"></i>Edit</a>
                                            <a class="dropdown-item" href="#"><i class="fas fa-redo-alt text-orange-peel"></i>Refresh</a>
                                        </div>
                                    </div> */}
                                </div>
                                <div class="single-info-details">
                                    {user_type !== undefined && user_type !== null && user_type.length > 0 &&
                                        <div class="item-img" style={{ height: "16.625rem", width: "16.625rem" }}>
                                            <img src={data.photo !== undefined && data.photo !== null && data.photo.length > 0 ? (`${multi_image_url}/uploads/${user_type}-photo/${data.photo}`) : (Team_1)} style={{ minWidth: "100%", minHeight: "100%" }} alt="teacher" />
                                        </div>
                                    }
                                    <div class="item-content">
                                        {/* <div class="header-inline item-header">
                                            <h3 class="text-dark-medium font-medium"></h3>
                                            <div class="header-elements">
                                                <ul>
                                                    <li><a href="#"><i class="far fa-edit"></i></a></li>
                                                    <li><a href="#"><i class="fas fa-print"></i></a></li>
                                                    <li><a href="#"><i class="fas fa-download"></i></a></li>
                                                </ul>
                                            </div>
                                        </div> */}
                                        <p></p>
                                        <div class="info-table table-responsive">
                                            <table class="table text-nowrap">
                                                <tbody>
                                                    {data.name &&
                                                        <tr>
                                                            <td>নাম:</td>
                                                            <td class="font-medium text-dark-medium">{data.name}</td>
                                                        </tr>
                                                    }
                                                    {data.designations_devide && data.designations_devide.length > 0 &&
                                                        <tr>
                                                            <td>উপাধি:</td>
                                                            <td class="font-medium text-dark-medium">{data.designations_devide[0].name}</td>
                                                        </tr>
                                                    }
                                                    {data.gender &&
                                                        <tr>
                                                            <td>লিঙ্গ:</td>
                                                            <td class="font-medium text-dark-medium">{data.gender}</td>
                                                        </tr>
                                                    }
                                                    {data.fathers_name &&
                                                        <tr>
                                                            <td>বাবার নাম:</td>
                                                            <td class="font-medium text-dark-medium">{data.fathers_name}</td>
                                                        </tr>
                                                    }

                                                    {data.joining_date &&
                                                        <tr>
                                                            <td>যোগদান তারিখ:</td>
                                                            <td class="font-medium text-dark-medium">{data.joining_date}</td>
                                                        </tr>
                                                    }
                                                    {data.email &&
                                                        <tr>
                                                            <td>ইমেইল:</td>
                                                            <td class="font-medium text-dark-medium">{data.email}</td>
                                                        </tr>
                                                    }
                                                    {data.department &&
                                                        <tr>
                                                            <td>বিভাগ:</td>
                                                            <td class="font-medium text-dark-medium">{data.department}</td>
                                                        </tr>
                                                    }
                                                    {data.gender &&
                                                        <tr>
                                                            <td>ঠিকানা:</td>
                                                            <td class="font-medium text-dark-medium">{data.present_address || "empty"}</td>
                                                        </tr>
                                                    }
                                                    {data.gender &&
                                                        <tr>
                                                            <td>ফোন:</td>
                                                            <td class="font-medium text-dark-medium">{data.phone || "empty"}</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                </div>
            </section>
        </>

    )
}
