import React, { useState } from 'react';
import dataContext from './dataContext';
import Http_Api from '../../Http_Api';
export default function DataStates(props) {
  const [url, setUrl] = useState(window.location.host)
  const [data, setData] = useState([]);
  const [content, setContent] = useState([]);
  const [users_data, setUsers_data] = useState([]);
  // const StorageUrl_Image = process.env.REACT_APP_API_STORAGE_IMAGE
  // const StorageUrl_logo = process.env.REACT_APP_API_STORAGE_LOGO
  const multi_image_url = process.env.REACT_APP_API_KEY_MAIN_DOMAIN_IMAGE;
  const baseURL = process.env.REACT_APP_API_KEY;
  const getData = async () => {
    let val = []
    // await Http_Api.get(`${url}`).then((res, req) => {
    //   val.push(res.data)
    //   console.log('hello');
    // }).catch(err=>{
    //   console.log(err);
    // })
    


    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open( "GET", `${baseURL}${url}`, false ); // false for synchronous request
    xmlHttp.send();
    val.push(JSON.parse(xmlHttp.response));
    setData(val);
    return val;
  }

  const getUserDataByUserId = async (id) => {
    let val1 = [];
    // await Http_Api.get(`${url}/users/` + id).then((res, req) => {
    //   val1.push(res.data);
    // }).catch(err=>{
    //   console.log(err);
    // })
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open( "GET", `${baseURL}${url}/users/${id}`, false ); // false for synchronous request
    xmlHttp.send( null );
    val1.push(JSON.parse(xmlHttp.response));
    setUsers_data(val1);
  }

  const getEventNewsById = async (table_name, id) => {
    let val = [];
    // await Http_Api.get(`${url}/${table_name}/${id}`).then((res, req) => {
    //   val.push(res.data);
    // }).catch(err=>{
    //   console.log(err);
    // })

    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open( "GET", `${baseURL}${url}/${table_name}/${id}`, false ); // false for synchronous request
    xmlHttp.send( null );
    val.push(JSON.parse(xmlHttp.response));
    setContent(val);
  }

  return (
    <dataContext.Provider value={{ multi_image_url,  url, users_data, setUrl, getData, data, getUserDataByUserId, getEventNewsById, content }}>
      {props.children}
    </dataContext.Provider>
  )
}
