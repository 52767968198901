import React, { useContext, useEffect, useRef, useState } from 'react'
import Contact from '../assets/images/contact.jpg';
import News_1 from "../assets/images/news1.png"
import { Link, useLocation, useParams } from 'react-router-dom';
import Http_Api from '../Http_Api';
import dataContext from '../context/data/dataContext';
export default function News_Event_view() {
    const location = useLocation()
    const context = useContext(dataContext);
    const { content, getEventNewsById, multi_image_url } = context;
    const { hash, pathname, search } = location;
    var array_path = pathname.split(/[/]+/)
    let main_title = null;
    let url = '';
    var events

    const [loading, setLoading] = useState(true);
    if (array_path.includes('News')) {
        // main_title = array_path[array_path.indexOf('News')]
        main_title = 'news';
        url = 'NewsAll'
    }
    if (array_path.includes('Event')) {
        // main_title = array_path[array_path.indexOf('Event')]
        main_title = 'event';
        url = 'EventAll'
    }
    // console.log(main_title)

    let { id } = useParams()
    // const [data_table_title, setData_table_title] = useState();

    const windoSCrollCount = useRef(0)
    useEffect(() => {
        array_path = pathname.split(/[/]+/)
        if (loading) {
            getEventNewsById(main_title, id);
        }
        if (windoSCrollCount.current === 0) {
            // console.log('asdfasfsa')
            window.scrollTo({ top: 0, behavior: "smooth" })
            windoSCrollCount.current = 1
        }
        let scrolling = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop
        setLoading(false);
    }, [])
    var news, event, latest_event, latest_news;
    if (content.length > 0) {
        var { news, event, latest_event, latest_news } = content[0];
    }

    const link_click = async (e, id_) => {
        let link_clicker_parent = e.target.closest('.link_clicker_parent');
        let link_click_element = link_clicker_parent.getElementsByClassName('link_click_element')[0].click();

        if (main_title !== null) {
            await getEventNewsById(main_title, id_);
        }
        setLoading(true)
    }
    return (
        <>
            {/* dont change the id and custom class   */}
            <section id="page-banner">
                <div className="banner-inner"

                    style={{ backgroundImage: `url(https://source.unsplash.com/random/1600x200/?islamic)` }}>

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="banner-contents">
                                    <h1 className='d-block'>{main_title}</h1>
                                    <nav className="breadcrumbs " style={{ opacity: "1" }}>
                                        <Link to={'/'} className="breadcrumbs__item"><i className="text-black fa-solid fa-house"></i> Home</Link>
                                        {/* <Link to={'/'} className="breadcrumbs__item">Shop</Link> */}
                                        {/* <Link to={'/'} className="breadcrumbs__item">Cart</Link> */}
                                        <Link to={'/'} className="breadcrumbs__item is-active">{main_title}</Link>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='News_Event_View' style={{ margin: "5% 0" }}>
                <div className="container">
                    <div className='mx-5'>
                        <Link to={'/' + url}>
                            <div className='py-2 px-3 rounded-pill bg-success text-white d-flex justify-content-between  ' style={{ width: '10rem', background: "linear-gradient(90deg, rgba(21,108,66,1) 38%, rgb(115 190 154) 92%)" }}>
                                <span>Go back</span>
                                <span><i className="fa-solid fa-angles-left"></i></span>
                            </div>
                        </Link>
                    </div>
                    <div className="row">
                        {main_title !== null && main_title === 'event' && (
                            <>
                                {event !== undefined ?
                                    event.map((data) => (
                                        <div className="col-lg-12">
                                            <div className='bg-white p-5 '>
                                                <h3 className='font-weight-bold'>{data.title}</h3>
                                                <p>
                                                    {/* {data.created_by !== null && data.created_by.length > 0 && */}
                                                    {/* <span style={{ marginRight: "2rem" }}>
                                                            <span style={{ marginRight: "0.5rem" }}>
                                                                <i className="fa-solid fa-circle-user"></i>
                                                            </span>
                                                            <span>
                                                                {data.created_by}
                                                            </span>
                                                        </span> */}
                                                    {/* } */}
                                                    <span style={{ marginRight: "1rem" }}>
                                                        <span style={{ marginRight: "0.5rem" }}>
                                                            <i className="fa-solid fa-calendar-days"></i>
                                                        </span>
                                                        <span>
                                                            {data.event_from !== null && data.event_from.length > 0 &&
                                                                (<> <span className=''> <span>From :</span> <span>{data.event_from}</span></span>
                                                                </>)
                                                            }
                                                            {
                                                                data.event_to !== null && data.event_to.length > 0 &&
                                                                (<>
                                                                    <span className='mx-2'><span >To :</span> <span>{data.event_to}</span></span>
                                                                </>)
                                                            }

                                                        </span>
                                                    </span>


                                                </p>
                                                <div>
                                                    {data.image !== null && data.image.length > 0 &&


                                                        <img className=' px-3' style={{ width: "50%", float: "inline-start", borderRadius: "5%" }} src={`${multi_image_url}/uploads/event/${data.image}`} alt="" />

                                                    }
                                                    {data.note !== null && data.note.length > 0 &&
                                                        <p className='' style={{ textAlign: "justify" }}>{data.note} Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea magni accusamus libero fugiat, ipsum hic, nemo placeat tenetur fugit recusandae laborum inventore accusantium eligendi debitis consequatur voluptatibus, minus a ab nesciunt atque at quibusdam sapiente. Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim iusto accusantium ratione, illum, obcaecati sed eveniet tenetur minus recusandae iste, reiciendis praesentium blanditiis non magnam ab quos ipsam veniam. Fugiat? Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum possimus voluptatum voluptatibus debitis obcaecati natus rerum repellat eos consectetur. Molestiae nesciunt cumque quia blanditiis aperiam hic illo soluta architecto dolores!
                                                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Neque sunt explicabo amet itaque, nostrum ipsam quisquam nisi deleniti molestias rem obcaecati, ipsum tenetur, et recusandae enim eligendi eaque maxime minus!

                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus maiores vero aut, quisquam animi ipsa magni culpa quaerat porro commodi tempore saepe voluptatum nesciunt error mollitia modi quod? Veniam, inventore.
                                                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Magni, corrupti maxime aliquid deserunt rem dolores doloribus laboriosam placeat saepe ullam accusamus sint neque reiciendis autem, dolorum non distinctio! Consequatur, omnis.</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)} </>)}

                        {main_title !== null && main_title === 'news' && (
                            <>
                                {news !== undefined && news.length > 0 ?
                                    news.map((data) => (
                                        <div className="col-lg-7">
                                            <div className='bg-white p-5 '>
                                                <h3 className='font-weight-bold'>{data.title}</h3>
                                                <p>
                                                    {/* {data.created_by !== null && data.created_by.length > 0 && */}
                                                    {/* <span style={{ marginRight: "2rem" }}>
                                                            <span style={{ marginRight: "0.5rem" }}>
                                                                <i className="fa-solid fa-circle-user"></i>
                                                            </span>
                                                            <span>
                                                                {data.created_by}
                                                            </span>
                                                        </span> */}
                                                    {/* } */}

                                                    <span style={{ marginRight: "1rem" }}>
                                                        <span style={{ marginRight: "0.5rem" }}>
                                                            <i className="fa-solid fa-calendar-days"></i>
                                                        </span>
                                                        <span>
                                                            {data.date !== null && data.date.length > 0 &&
                                                                (<><span>{data.date}</span>
                                                                </>)
                                                            }


                                                        </span>
                                                    </span>


                                                </p>
                                                {data.image !== null && data.image.length > 0 &&

                                                    <img className='rounded' style={{ width: "100%" }} src={`${multi_image_url}/uploads/news/${data.image}`} alt="" />
                                                }
                                                {data.news !== null && data.news.length > 0 &&
                                                    <p className='mt-4'>{data.news}</p>
                                                }
                                            </div>
                                        </div>
                                    )) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)} </>)}
                        {/* dont change the id and custom class   */}
                        <div id='history' className="col-lg-5 " >

                            {/* {main_title !== undefined && main_title === 'event' &&
                                <>
                                    <div className='bg-white pt-5'>
                                        <h5 className='font-weight-bold'><span style={{ marginRight: "0.8rem" }}><i className="fa-solid fa-box-archive"></i></span> সর্বশেষ সংবাদ</h5>
                                        <hr className='h-1' style={{ borderTop: "2px solid #156C42", marginTop: "0", marginBottom: '0.8rem' }} />
                                        <div className='history '>
                                            {latest_event !== undefined && latest_event !== null && latest_event.length > 0 ? latest_event.map((data) => (
                                                <div className='d-flex mb-5 link_clicker_parent' style={{ cursor: "pointer" }} onClick={(e) => { link_click(e, `${data.id}`) }}>
                                                    <img style={{ borderRadius: "0.5rem", width: "10rem", height: "7rem" }} src={`${multi_image_url}/uploads/event/${data.image !== null && data.image}`} alt="" />
                                                    <p className='d-flex flex-column h6' style={{ marginLeft: "0.8rem" }}><Link className='link_click_element' to={`/Event/${data.id}`}>{data.title !== null && data.title.slice(0, 100)}...</Link>
                                                        <span className='d-flex justify-content-between flex-wrap h6 mt-3'>
                                                            <span className='' style={{ marginRight: "0.5rem", marginLeft: "0" }}>
                                                                <span className=''  ><i className="fa-solid fa-circle-user"></i></span><span className='mx-1'>User</span>
                                                            </span>
                                                            {((data.event_from !== null && data.event_from.length > 0) || (data.event_to !== null && data.event_to.length > 0)) &&
                                                                <span className='row'>
                                                                    <span className='col-1'><i className="fa-solid fa-calendar-days"></i></span>
                                                                    <span className='col-10'>
                                                                        {(data.event_from !== null && data.event_from.length > 0) && <span><span>From :</span> <span id='date_from'>{data.event_from}</span></span>} <br />
                                                                        {(data.event_to !== null && data.event_to.length > 0) && <span ><span>To :</span> <span id='date_to'>{data.event_to}</span></span>}


                                                                    </span>
                                                                </span>
                                                            }
                                                        </span>
                                                    </p>
                                                </div>
                                            )) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                        </div>
                                    </div>
                                </>
                            } */}
                            {main_title !== undefined && main_title === 'news' &&
                                <>
                                    <div className='bg-white pt-5'>
                                        <h5 className='font-weight-bold'><span style={{ marginRight: "0.8rem" }}><i className="fa-solid fa-box-archive"></i></span> সর্বশেষ সংবাদ</h5>
                                        <hr className='h-1' style={{ borderTop: "2px solid #156C42", marginTop: "0", marginBottom: '0.8rem' }} />
                                        <div className='history '>
                                            {latest_news !== undefined && latest_news !== null && latest_news.length > 0 ? latest_news.map((data) => (
                                                <div className='d-flex mb-5 link_clicker_parent' style={{ cursor: "pointer" }} onClick={(e) => { link_click(e, `${data.id}`) }}>
                                                    <img style={{ borderRadius: "0.5rem", width: "10rem" }} src={`${multi_image_url}/uploads/news/${data.image !== null && data.image}`} alt="" />
                                                    <p className='d-flex flex-column h6' style={{ marginLeft: "0.8rem" }}><Link to={`/News/${data.id}`} className='link_click_element'>{data.title !== null && data.title.slice(0, 100)}...</Link>
                                                        <span className='d-flex justify-content-between flex-wrap h6 mt-3'>
                                                            {/* <span className='' style={{ marginRight: "0.5rem", marginLeft: "0" }}>
                                                                <span className=''  ><i className="fa-solid fa-circle-user"></i></span><span className='mx-1'>User</span>
                                                            </span> */}
                                                            {data.date !== null && data.date.length > 0 &&
                                                                <span className='row'>
                                                                    <span className='col-1'><i className="fa-solid fa-calendar-days"></i></span>
                                                                    <span className='col-9'>
                                                                        <span><span id='at_from_to_section_text'></span> <span id='date_from'>{data.date}</span></span> <br />
                                                                    </span>
                                                                </span>
                                                            }
                                                        </span>
                                                    </p>
                                                </div>
                                            )) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                        </div>
                                    </div>
                                </>
                            }

                        </div>

                    </div>
                </div >
            </section >
        </>
    )
}
