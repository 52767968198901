import React, { useContext, useEffect, useRef, useState } from 'react'
import Hero_1 from "../assets/images/hero1.png";
import Counter from "../assets/images/counter.png";
import News_1 from "../assets/images/news1.png";
import D_1 from "../assets/images/d1.png";
import S_1 from "../assets/images/s1.png";
import Star from "../assets/images/5star.png";
import Team_1 from '../assets/images/team1.png'
import Team_2 from '../assets/images/team2.png'
import Team_3 from '../assets/images/team3.png'
import Testimonial_1 from '../assets/images/testimonial1.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'swiper/css/effect-fade';
import { Autoplay, Navigation, Pagination, EffectFade } from 'swiper/modules';
import 'swiper/css';
import { Link, json } from 'react-router-dom';
import Http from '../Http_Api';
import { formToJSON } from 'axios';
import dataContext from "../context/data/dataContext";
export default function Home() {
    const context = useContext(dataContext);
    const { data, getData, multi_image_url } = context;
    const windoSCrollCount = useRef(0)
    const currenttime = useRef(3)
    const base_bulian = useRef(false);
    const empty_team = useRef(0);
    const sno = useRef(null)
    const host = window.location.host
    let user_catagory = useRef('advisoryBorde');


    const [dataTestimonial_title, setDataTestimonial_title] = useState([])
    const [dataTestimonial_inner, setDataTestimonial_inner] = useState([])
    const [dataNews_title, setDataNews_title] = useState([])
    const [dataNews_inner, setDataNews_inner] = useState([])
    const [dataEvent_title, setDataEvent_title] = useState([])
    const [dataEvent_inner, setDataEvent_inner] = useState([])
    useEffect(() => {
        // fetch_data_from_database();
        if (windoSCrollCount.current === 0) {
            window.scrollTo({ top: 0, behavior: "smooth" })
            windoSCrollCount.current = 1
        }
        let scrolling = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop
        Load_More_Service()
        return () => {
            // Anything in here is fired on component unmount.
            Load_More_Service()

        }


    }, [])


    const Team_vissible = (e, name) => {
        let element = e.target.closest('.tablinks')
        let tablinks = document.getElementsByClassName('tablinks')
        for (let index = 0; index < tablinks.length; index++) {
            const element = tablinks[index];
            if (element.classList.contains('active')) {
                element.classList.remove('active');
            }
        }
        if (!element.classList.contains('active')) {
            element.classList.add('active');
        }


        let employee = document.getElementById(name);
        let employee_all = document.getElementsByClassName('team_member_by_catagory');
        for (let index = 0; index < employee_all.length; index++) {
            const element = employee_all[index];
            if (element.classList.contains('d-block')) {
                element.classList.add('d-none')
                element.classList.remove('d-block')
            }
        }
        // setUser_catagory(name);
        if (employee.classList.contains('d-none')) {
            employee.classList.add('d-block')
            employee.classList.remove('d-none')
        }
        user_catagory.current = name
        // get_all_employee();
    }



    // const Team_vissible = (evt, cityName) => {
    //     let i, tabcontent, tablinks;
    //     tabcontent = document.getElementsByClassName("tabcontent");
    //     for (i = 0; i < tabcontent.length; i++) {
    //         tabcontent[i].style.display = "none";
    //     }
    //     tablinks = document.getElementsByClassName("tablinks");
    //     for (i = 0; i < tablinks.length; i++) {
    //         tablinks[i].className = tablinks[i].className.replace(" active", "");
    //     }
    //     document.getElementById(cityName).style.display = "block";
    //     evt.currentTarget.className += " active";

    // }

    const Load_More_Service = (e = null) => {
        let wrapper = document.querySelectorAll('.container .row .wrapper');

        if (e == null && services) {
            for (let index = 3; index < wrapper.length; index++) {
                const element = wrapper[index];
                element.style.display = 'none'
            }
        }
        if (e) {
            let cta_btn = e.target.closest('#cta-btn');
            // let wrapper = document.getElementsByClassName('wrapper')
            let b = false
            // let currenttime = 3
            let endlimit = wrapper.length
            // console.log(endlimit)
            if (base_bulian.current == false) {
                for (let i = currenttime.current; i < currenttime.current + 3; i++) {
                    if (wrapper[i] != null) {
                        wrapper[i].style.display = 'inline-block'
                    }
                }
                currenttime.current = currenttime.current + 3;
                if (currenttime.current >= endlimit) {
                    base_bulian.current = true
                    cta_btn.lastElementChild.innerText = 'প্রদর্শন কম দেখুন';
                }
                // console.log(currenttime.current)
            }

            else if (base_bulian.current === true) {
                // console.log('hell')
                if (currenttime.current >= 3) {
                    for (let inf = currenttime.current; inf > currenttime.current - 4; inf--) {
                        if (wrapper[inf] != null) {
                            wrapper[inf].style.display = 'none';
                        }
                        // console.log(inf)
                    }
                    currenttime.current = currenttime.current - 3

                    if (currenttime.current == 3) {
                        base_bulian.current = false
                        cta_btn.lastElementChild.innerText = 'আমাদের পরিসেবা আরো দেখুন';
                    }
                    // console.log(currenttime.current)
                }
            }
        }


        // console.log(currenttime.current, b)

    }

    let short_data_news_inner = []
    let short_data_event_inner = [];
    if (dataNews_inner.length > 0) {
        for (let index = 0; index < 3; index++) {
            const element = dataNews_inner[index];
            short_data_news_inner.push(element)
            // console.log(element)
        }
    }
    if (dataEvent_inner.length > 0) {
        for (let index = 1; index < 4; index++) {
            const element = dataEvent_inner[index];
            short_data_event_inner.push(element);
        }
    }


    var about_text, school_name, slider, differences, services, school_success, student, teacher, accountant, librarian, receptioniast, staff, servent, data_entry_operator, employee_number = 0, team_teachers, team_employees_catagory_1, team_employees_catagory_2, team_employees_catagory_3, news, events;
    if (data.length > 0) {

        var { school_name, about_text, slider, differences, services, school_success, student, teacher, accountant, librarian, receptioniast, staff, servent, data_entry_operator, team_teachers, team_employees_catagory_1, team_employees_catagory_2, team_employees_catagory_3, news, testimonials, events } = data[0];
    }

    if (slider && differences && services && news && testimonials && events && school_success && team_teachers && team_employees_catagory_1 && team_employees_catagory_2 && team_employees_catagory_3) {

        let s1 = [];
        let s2 = [];
        let s3 = [];
        let s4 = [];
        let s5 = [];
        let s6 = [];
        let s7 = [];
        let s8 = [];
        let s9 = [];
        let s10 = [];
        let s11 = [];
        Object.keys(slider).map((key) => {
            s1.push(slider[key])
        })
        Object.keys(differences).map((key) => {
            s2.push(differences[key])
        })
        Object.keys(services).map((key) => {
            s3.push(services[key])
        })
        Object.keys(news).map((key) => {
            s4.push(news[key])
        })
        Object.keys(testimonials).map((key) => {
            s5.push(testimonials[key])
        })
        Object.keys(events).map((key) => {
            if (s6.length === 4) {
                return
            }
            s6.push(events[key])
        })
        Object.keys(team_teachers).map((key) => {
            s7.push(team_teachers[key])
        })
        Object.keys(team_employees_catagory_1).map((key) => {
            s8.push(team_employees_catagory_1[key])
        })
        Object.keys(team_employees_catagory_2).map((key) => {
            s9.push(team_employees_catagory_2[key])
        })
        Object.keys(team_employees_catagory_3).map((key) => {
            s10.push(team_employees_catagory_3[key])
        })
        Object.keys(school_success).map((key) => {
            s11.push(school_success[key])
        })

        slider = s1
        differences = s2
        services = s3
        news = s4
        testimonials = s5
        events = s6
        team_teachers = s7
        team_employees_catagory_1 = s8
        team_employees_catagory_2 = s9
        team_employees_catagory_3 = s10
        school_success = s11
        // console.log(testimonials)
        let wrapper = document.getElementsByClassName('wrapper');
        for (let index = 3; index < wrapper.length; index++) {
            const element = wrapper[index];
            element.style.display = 'none';
        }
    }

    if (accountant != undefined && librarian != undefined && receptioniast != undefined && staff != undefined && servent != undefined && data_entry_operator != undefined) {
        employee_number = accountant + librarian + receptioniast + staff + data_entry_operator;
    }
    var advisoryBorde, official, teacher, employee;
    const Catagory_data_list = ['advisoryBorde', 'official', 'teacher', 'employee'];

    const updatemptyteam = (number, incriment) => {
        if (number !== null && number !== undefined && number.length !== 0) {
            empty_team.current = number;
        }
        if (incriment) {
            empty_team.current++
        }
    }

    return (
        <>
            <section id="hero">
                <div className="hero-carousel owl-carousel">
                    <Swiper
                        autoplay={{
                            delay: 4000,
                            disableOnInteraction: true
                        }}
                        speed={1500}
                        className="mySwiper"
                        pagination={{
                            el: '.swiper-pagination',
                            clickable: true,
                            renderBullet: function (index, className) {
                                return '<span class="' + className + ' my_sigle_bullet_white"></span>';
                            },
                        }}
                        effect={'fade'}
                        modules={[Autoplay, Pagination, EffectFade]}
                    >

                        {slider && slider !== undefined && ((slider).map((data) => (
                            <SwiperSlide>
                                <div className="single-hero-item responsive-image"
                                    style={{ backgroundImage: `url(${multi_image_url}/uploads/slider/${data.image})`, backgroundPosition: "center", backgroundSize: "cover" }}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="hero-contents">
                                                    <h1 className='fadeinleft'>
                                                        {data.title && data.title.length > 0 ?
                                                            (data.title)
                                                            : (<>{(school_name && school_name.length > 0 && school_name)}</>)}
                                                    </h1>
                                                    <p className='fadeinright'>{data.description !== null && data.description.length > 0 && data.description.slice(0, 200)}</p>
                                                    <ul className='fadeindown'>
                                                        {data.first_button_vissibility !== null && data.first_button_vissibility === 1 &&
                                                            <li><Link to={`/${data.first_button_link}`}>{data.first_button_text}</Link></li>
                                                        }
                                                        {data.second_button_vissibility !== null && data.second_button_vissibility === 1 &&

                                                            <li><Link to={`/${data.second_button_link}`}>{data.second_button_text}</Link></li>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )))}
                        {/* <SwiperSlide>
                            <div className="single-hero-item"
                                style={{ backgroundImage: `url(${Hero_1})` }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="hero-contents">
                                                <h1 className='fadeinleft'>কওমী ও আধুনিক শিক্ষার সমন্বয়ে একটি স্বতন্ত্র দ্বীনি শিক্ষা প্রতিষ্ঠান</h1>
                                                <p className='fadeinright'>মারকাযু তা’লীমিস সুন্নাহ জামালপুর। পথ চলার ৪র্থ বছর। ইলমে ওহী ও ইলমে নববীর অন্বেষণে
                                                    উজ্জীবিত হয়ে এর পথচলা। যুগপোযোগী ও সৃজনশীল শিক্ষাদান পদ্ধতি প্রতিষ্ঠানটির অনন্য
                                                    অলংকার। এলেম ও আমলের সমন্বয়ে উন্নততর জীবন গঠনের অনুপম অনুশীলন কেন্দ্র। শিশু শ্রেণি,
                                                    নাজেরা, হিফজুল কুরআন ও কিতাব বিভাগ দিয়ে সাজানো হয়েছে অত্র প্রতিষ্ঠানের শিক্ষা পরিসর।
                                                    আসাতিযায়ে কেরামের সার্বক্ষণিক নেগরানির মাধ্যমে আকাবির ও আসলাফের যোগ্য উত্তরসুরি
                                                    হিসেবে যুগ সচেতন আলেমে-দ্বীন তৈরী করার লক্ষ্যে এক নিরলস প্রচেষ্টা চালিয়ে যাচ্ছে
                                                    প্রতিষ্ঠানটি। </p>
                                                <ul className='fadeindown'>
                                                    <li><a href="#">অনুদান</a></li>
                                                    <li><a href="#">ভর্তি সম্পর্কে</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="single-hero-item"
                                style={{ backgroundImage: `url(https://source.unsplash.com/random/720x720/?islamic)` }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="hero-contents">
                                                <h1 className='fadeinleft'>কওমী ও আধুনিক শিক্ষার সমন্বয়ে একটি স্বতন্ত্র দ্বীনি শিক্ষা প্রতিষ্ঠান</h1>
                                                <p className='fadeinright'>মারকাযু তা’লীমিস সুন্নাহ জামালপুর। পথ চলার ৪র্থ বছর। ইলমে ওহী ও ইলমে নববীর অন্বেষণে
                                                    উজ্জীবিত হয়ে এর পথচলা। যুগপোযোগী ও সৃজনশীল শিক্ষাদান পদ্ধতি প্রতিষ্ঠানটির অনন্য
                                                    অলংকার। এলেম ও আমলের সমন্বয়ে উন্নততর জীবন গঠনের অনুপম অনুশীলন কেন্দ্র। শিশু শ্রেণি,
                                                    নাজেরা, হিফজুল কুরআন ও কিতাব বিভাগ দিয়ে সাজানো হয়েছে অত্র প্রতিষ্ঠানের শিক্ষা পরিসর।
                                                    আসাতিযায়ে কেরামের সার্বক্ষণিক নেগরানির মাধ্যমে আকাবির ও আসলাফের যোগ্য উত্তরসুরি
                                                    হিসেবে যুগ সচেতন আলেমে-দ্বীন তৈরী করার লক্ষ্যে এক নিরলস প্রচেষ্টা চালিয়ে যাচ্ছে
                                                    প্রতিষ্ঠানটি। </p>
                                                <ul className='fadeindown'>
                                                    <li><a href="#">অনুদান</a></li>
                                                    <li><a href="#">ভর্তি সম্পর্কে</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="single-hero-item"
                                style={{ backgroundImage: `url(https://source.unsplash.com/random/720x720/?nature)` }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="hero-contents">
                                                <h1 className='fadeinleft'>কওমী ও আধুনিক শিক্ষার সমন্বয়ে একটি স্বতন্ত্র দ্বীনি শিক্ষা প্রতিষ্ঠান</h1>
                                                <p className='fadeinright'>মারকাযু তা’লীমিস সুন্নাহ জামালপুর। পথ চলার ৪র্থ বছর। ইলমে ওহী ও ইলমে নববীর অন্বেষণে
                                                    উজ্জীবিত হয়ে এর পথচলা। যুগপোযোগী ও সৃজনশীল শিক্ষাদান পদ্ধতি প্রতিষ্ঠানটির অনন্য
                                                    অলংকার। এলেম ও আমলের সমন্বয়ে উন্নততর জীবন গঠনের অনুপম অনুশীলন কেন্দ্র। শিশু শ্রেণি,
                                                    নাজেরা, হিফজুল কুরআন ও কিতাব বিভাগ দিয়ে সাজানো হয়েছে অত্র প্রতিষ্ঠানের শিক্ষা পরিসর।
                                                    আসাতিযায়ে কেরামের সার্বক্ষণিক নেগরানির মাধ্যমে আকাবির ও আসলাফের যোগ্য উত্তরসুরি
                                                    হিসেবে যুগ সচেতন আলেমে-দ্বীন তৈরী করার লক্ষ্যে এক নিরলস প্রচেষ্টা চালিয়ে যাচ্ছে
                                                    প্রতিষ্ঠানটি। </p>
                                                <ul className='fadeindown'>
                                                    <li><a href="#">অনুদান</a></li>
                                                    <li><a href="#">ভর্তি সম্পর্কে</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide> */}
                        <div className='swiper-pagination my_swiper_pagination'></div>
                    </Swiper>
                </div>
            </section>


            <section id="diffrences">
                <div className="container">
                    <div className="row">
                        <div className="col-12">

                            <div className="section-title">
                                <h2>যে কারণে আমরা ব্যতিক্রম</h2>
                                <p>শিক্ষা কার্যক্রমকে আকাবের ও আসলাফের মূলনীতির উপর অবিচল রেখে যুগোপযোগী করে সাজানো হয়েছে। তা’লীম ও তারবিয়্যাতের সকল পর্বে বিশ্ব বিখ্যাত বিদ্যাপীঠ দারুল উলূম দেওবন্দের মানহায ও মতাদর্শকেই গ্রহণ করা হয়েছে</p>
                            </div>
                            <div className="">
                                <Swiper
                                    speed={3000}
                                    loop={true}
                                    slidesPerView={1}
                                    pagination={{
                                        el: '.swiper-pagination',
                                        clickable: true,
                                        renderBullet: function (index, className) {
                                            return '<span class="' + className + ' my_sigle_bullet"></span>';
                                        },
                                    }}
                                    modules={[Pagination]}
                                    breakpoints={{
                                        // when window width is >= 640px
                                        640: {
                                            slidesPerView: 1,
                                        },
                                        // when window width is >= 768px
                                        768: {
                                            slidesPerView: 2,
                                        },
                                        1010: {
                                            slidesPerView: 3,
                                        }
                                    }}
                                    className="mySwiper"
                                >
                                    {differences !== null && differences !== undefined ? ((differences).map((data) => (
                                        <SwiperSlide style={{ paddingBottom: '2rem' }}>
                                            <div className="single-difference-item">
                                                <img src={(data.photo !== null && data.photo.length > 0 ? (`${multi_image_url}/uploads/difference/${data.photo}`) : (D_1))}
                                                    alt="" />
                                                <h3>{data.title.slice(0, 30)}...</h3>
                                                <p>{data.description.slice(0, 150)}...</p>
                                                <Link to={'/LatestNews'}>বিস্তারিত পড়ুন</Link>
                                            </div>
                                        </SwiperSlide>
                                    ))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                    {/* <SwiperSlide style={{ paddingBottom: '2rem' }}>
                                        <div className="single-difference-item">
                                            <img src={D_1}
                                                alt="" />
                                            <h3>মানসম্পূন্ন শিক্ষা ব্যবস্থা</h3>
                                            <p>যোগ্য, চরিত্রবান, ইজাজাহপ্রাপ্ত ও প্রশিক্ষিত শিক্ষকবৃন্দের মাধ্যমে পাঠদান করা হয়।
                                                শিক্ষাদানের ক্ষেত্রে ইজরা, তামরীন, মশক ও প্রচুর অনুশীলনী পদ্ধতির মাধ্যমে পড়া</p>
                                            <Link to={"/LatestNews"}>বিস্তারিত পড়–ন</Link>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide style={{ paddingBottom: '2rem' }}>
                                        <div className="single-difference-item">
                                            <img src={D_1}
                                                alt="" />
                                            <h3>সমন্বিত সিলেবাস</h3>
                                            <p>আল কোরান শিক্ষার পাশাপাশি আরবী, উদূ, ফার্সী, বাংলা, ইংরেজী, গনিত, কম্পিউটার সহ মৌলিক
                                                বিষয়গুলো গুরুত্বের সাথে শ্রেণিভিত্তক নিয়মিত পাঠদান করা হয়।</p>
                                            <Link to={"/LatestNews"}>বিস্তারিত পড়–ন</Link>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide style={{ paddingBottom: '2rem' }}>
                                        <div className="single-difference-item">
                                            <img src={D_1}
                                                alt="" />
                                            <h3>সমন্বিত সিলেবাস</h3>
                                            <p>আল কোরান শিক্ষার পাশাপাশি আরবী, উদূ, ফার্সী, বাংলা, ইংরেজী, গনিত, কম্পিউটার সহ মৌলিক
                                                বিষয়গুলো গুরুত্বের সাথে শ্রেণিভিত্তক নিয়মিত পাঠদান করা হয়।</p>
                                            <Link to={"/LatestNews"}>বিস্তারিত পড়–ন</Link>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide style={{ paddingBottom: '2rem' }}>
                                        <div className="single-difference-item">
                                            <img src={D_1}
                                                alt="" />
                                            <h3>সমন্বিত সিলেবাস</h3>
                                            <p>আল কোরান শিক্ষার পাশাপাশি আরবী, উদূ, ফার্সী, বাংলা, ইংরেজী, গনিত, কম্পিউটার সহ মৌলিক
                                                বিষয়গুলো গুরুত্বের সাথে শ্রেণিভিত্তক নিয়মিত পাঠদান করা হয়।</p>
                                            <Link to={"/LatestNews"}>বিস্তারিত পড়–ন</Link>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide style={{ paddingBottom: '2rem' }}>
                                        <div className="single-difference-item">
                                            <img src={D_1}
                                                alt="" />
                                            <h3>সমন্বিত সিলেবাস</h3>
                                            <p>আল কোরান শিক্ষার পাশাপাশি আরবী, উদূ, ফার্সী, বাংলা, ইংরেজী, গনিত, কম্পিউটার সহ মৌলিক
                                                বিষয়গুলো গুরুত্বের সাথে শ্রেণিভিত্তক নিয়মিত পাঠদান করা হয়।</p>
                                            <Link to={"/LatestNews"}>বিস্তারিত পড়–ন</Link>
                                        </div>
                                    </SwiperSlide> */}
                                    <div className="swiper-pagination"></div>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section id="services">
                <div className="container " style={{ padding: " 0 5rem " }}>

                    <div className="section-title">
                        <h2>আমাদের সেবাসমূহ</h2>
                    </div>
                    <div className="row"
                        id="row">
                        {services && services.map((data) => (
                            <div className="col-lg-4 col-md-4 col-12 wrapper">
                                <div className="single-services">
                                    <div className="services-icon">
                                        <img src={(data.photo !== null && data.photo.length > 0 ? (`${multi_image_url}/uploads/service/${data.photo}`) : (S_1))}
                                            alt="" />
                                    </div>
                                    <div className="services-contents">
                                        <p>{data.title.slice(0, 50)}</p>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {/* <div className="col-lg-4 col-md-4 col-12 wrapper">
                            <div className="single-services">
                                <div className="services-icon">
                                    <img src={S_1}
                                        alt="" />
                                </div>
                                <div className="services-contents">
                                    <p>আধুনিক, নিরাপদ ও উন্নতমানের হোস্টেল ব্যবস্থাপনা</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12 wrapper">
                            <div className="single-services">
                                <div className="services-icon">
                                    <img src={S_1}
                                        alt="" />
                                </div>
                                <div className="services-contents">
                                    <p>আধুনিক, নিরাপদ ও উন্নতমানের হোস্টেল ব্যবস্থাপনা</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12 wrapper" >
                            <div className="single-services">
                                <div className="services-icon">
                                    <img src={S_1}
                                        alt="" />
                                </div>
                                <div className="services-contents">
                                    <p>আধুনিক, নিরাপদ ও উন্নতমানের হোস্টেল ব্যবস্থাপনা</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12 wrapper" >
                            <div className="single-services">
                                <div className="services-icon">
                                    <img src={S_1}
                                        alt="" />
                                </div>
                                <div className="services-contents">
                                    <p>আধুনিক, নিরাপদ ও উন্নতমানের হোস্টেল ব্যবস্থাপনা</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12 wrapper" >
                            <div className="single-services">
                                <div className="services-icon">
                                    <img src={S_1}
                                        alt="" />
                                </div>
                                <div className="services-contents">
                                    <p>আধুনিক, নিরাপদ ও উন্নতমানের হোস্টেল ব্যবস্থাপনা</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12 wrapper" >
                            <div className="single-services">
                                <div className="services-icon">
                                    <img src={S_1}
                                        alt="" />
                                </div>
                                <div className="services-contents">
                                    <p>আধুনিক, নিরাপদ ও উন্নতমানের হোস্টেল ব্যবস্থাপনা</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12 wrapper" >
                            <div className="single-services">
                                <div className="services-icon">
                                    <img src={S_1}
                                        alt="" />
                                </div>
                                <div className="services-contents">
                                    <p>আধুনিক, নিরাপদ ও উন্নতমানের হোস্টেল ব্যবস্থাপনা</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12 wrapper" >
                            <div className="single-services">
                                <div className="services-icon">
                                    <img src={S_1}
                                        alt="" />
                                </div>
                                <div className="services-contents">
                                    <p>আধুনিক, নিরাপদ ও উন্নতমানের হোস্টেল ব্যবস্থাপনা</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    {services && services.length > 3 &&
                        <div className="align-middle">
                            <div onClick={(e) => { Load_More_Service(e) }}
                                id='cta-btn' className="cta-btn">
                                <Link to={'#'} >আমাদের পরিসেবা আরো দেখুন</Link>
                            </div>
                        </div>
                    }
                </div>
            </section >


            <section id="counter">
                <div className="conter-inner"
                    style={{
                        backgroundImage: `url(${Counter})`, overflow: "hidden",
                        padding: "100px 0 50px",
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundAttachment: "fixed",
                        position: 'relative',
                    }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="single-counter">
                                    <p><strong>{student !== undefined && student !== null ? student : 0}</strong></p>
                                    <p>শিক্ষার্থী</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="single-counter">
                                    <p><strong>{teacher !== undefined && teacher !== null ? teacher : 0}</strong></p>
                                    <p>শিক্ষক</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="single-counter">
                                    <p><strong>{servent !== undefined && servent !== null ? servent : 0}</strong></p>
                                    <p>কর্মকর্তা</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="single-counter">
                                    <p><strong>{employee_number !== undefined && employee_number !== null ? employee_number : 0}</strong></p>
                                    <p>কর্মচারী</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section id="motive">
                <div className="container" style={{ padding: " 0 5rem " }}>
                    {school_success !== undefined && school_success !== null & school_success.length > 0 ? ((school_success.map((data) => (
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="motive-head">
                                    <h2>{data.message}</h2>
                                </div>
                            </div>

                            <div className="col-lg-5">

                                <div className="motive-contents">
                                    <h3 style={{ marginBottom: "1rem" }}>{data.title} এর শিক্ষা সাফল্য</h3>
                                    <div style={{ width: "10rem" }}>
                                        <img src={data.image !== null && data.image.length > 0 ? `${multi_image_url}/uploads/school_success/${data.image}` : `${Star}`} alt="" style={{ minHeight: "100%", minWidth: "100%" }} />
                                    </div>
                                    <p className='mt-3'>{data.description}</p>
                                </div>
                            </div>
                        </div>
                    )))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                </div>
            </section>


            <section id="team">
                <div className="container" style={{ padding: " 0 5rem " }}>
                    <div className="row">
                        <div className="col-12 col-md-12 mx-auto">
                            <div className="section-title">
                                <h2>সেবা প্রদানকারী ব্যক্তিগন</h2>
                                <p>এই প্রতিষ্ঠানটিকে সাফল্যমন্ডিত করতে ও দরবারে এলাহিতে মাকবুলিয়্যাতের লক্ষ্যে যারা নিরলস শ্রম ও প্রচেষ্টা অব্যহত রেখেছেন</p>
                                <div className="tab">
                                    <span className="tablinks btn btn-outline-light mx-2  active"
                                        onClick={(e) => { Team_vissible(e, 'advisoryBorde') }}>উপদেষ্টামন্ডলী</span>
                                    <span className="tablinks btn btn-outline-light mx-2"
                                        onClick={(e) => { Team_vissible(e, 'official') }}>কর্মকর্তা</span>
                                    <span className="tablinks btn btn-outline-light mx-2"
                                        onClick={(e) => { Team_vissible(e, 'teacher') }}>শিক্ষক</span>
                                    <span className="tablinks btn btn-outline-light mx-2"
                                        onClick={(e) => { Team_vissible(e, 'employee') }}>কর্মচারী</span>
                                </div >
                            </div >
                        </div >

                        <div className='col-12 col-md-12 mx-auto'>
                            {/* <div id={"advisoryBorde"} className={"team_member_by_catagory d-block"}>
                                <div className="row ">
                                    {team_employees_catagory_1 !== undefined && team_employees_catagory_1 !== null && team_employees_catagory_1.length > 0 ? (team_employees_catagory_1.map((data1) => (<>
                                        {data1.designations_devide.length > 0 ? (data1.designations_devide.map((data2) => (
                                            <div className="col-7 col-sm-6 mx-auto col-md-5 col-lg-3 my-4">
                                                <div className="single-team">
                                                    <div className="team-image">
                                                        <img src={Team_1}
                                                            alt="" />
                                                    </div>
                                                    <div className="team-member-details">
                                                        <h4>{data2.name}</h4>
                                                        <p>{data1.name}</p>
                                                        <Link to={`/Manage_profile/${data2.user_id}`}>সম্পূর্ণ প্রোফাইল</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                    </>
                                    ))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                </div>
                            </div>
                            <div id={"official"} className={"team_member_by_catagory d-none"}>

                                <div className="row ">
                                    {team_employees_catagory_2 !== undefined && team_employees_catagory_2 !== null && team_employees_catagory_2.length > 0 ? (team_employees_catagory_2.map((data1) => (<>
                                        {data1.designations_devide.length > 0 ? (data1.designations_devide.map((data2) => (
                                            <div className="col-7 col-sm-6 mx-auto col-md-5 col-lg-3 my-4">
                                                <div className="single-team">
                                                    <div className="team-image">
                                                        <img src={Team_1}
                                                            alt="" />
                                                    </div>
                                                    <div className="team-member-details">
                                                        <h4>{data2.name}</h4>
                                                        <p>{data1.name}</p>
                                                        <Link to={`/Manage_profile/${data2.user_id}`}>সম্পূর্ণ প্রোফাইল</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                    </>
                                    ))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                </div>
                            </div>
                            <div id={"teacher"} className={"team_member_by_catagory d-none"}>
                                <div className="row ">
                                    {team_teachers !== undefined && team_teachers !== null ? (team_teachers.map((data) => (
                                        <div className="col-7 col-sm-6 mx-auto col-md-5 col-lg-3 my-4">
                                            <div className="single-team">
                                                <div className="team-image">
                                                    <img src={Team_1}
                                                        alt="" />
                                                </div>
                                                <div className="team-member-details">
                                                    <h4>{data.name}</h4>
                                                    <p>শিক্ষক</p>
                                                    <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                                </div>
                                            </div>
                                        </div>))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                </div>
                            </div>
                            <div id={"employee"} className={"team_member_by_catagory d-none"}>

                                <div className="row ">
                                    {school_success !== undefined && team_employees_catagory_3 !== null && team_employees_catagory_3.length > 0 ? (team_employees_catagory_3.map((data1) => (<>
                                        {data1.designations_devide.length > 0 ? (data1.designations_devide.map((data2) => (
                                            <div className="col-7 col-sm-6 mx-auto col-md-5 col-lg-3 my-4">
                                                <div className="single-team">
                                                    <div className="team-image">
                                                        <img src={Team_1}
                                                            alt="" />
                                                    </div>
                                                    <div className="team-member-details">
                                                        <h4>{data2.name}</h4>
                                                        <p>{data1.name}</p>
                                                        <Link to={`/Manage_profile/${data2.user_id}`}>সম্পূর্ণ প্রোফাইল</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                    </>
                                    ))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                </div>
                            </div> */}


                            <div className={"team_member_by_catagory d-block"} id="advisoryBorde">
                                <Swiper
                                    slidesPerView={2}
                                    spaceBetween={20}
                                    grabCursor={true}
                                    loop={true}
                                    pagination={{
                                        el: '.swiper-pagination',
                                        clickable: true,
                                        renderBullet: function (index, className) {
                                            return '<span class="' + className + ' my_sigle_bullet_color_1"></span>';
                                        },
                                    }}
                                    modules={[Pagination]}
                                    breakpoints={{

                                        640: {
                                            slidesPerView: 2,
                                        },


                                        768: {
                                            slidesPerView: 3,
                                        },
                                        1200: {
                                            slidesPerView: 4
                                        }
                                    }}
                                    className="mySwiper">
                                    {updatemptyteam(0, false)}
                                    {team_employees_catagory_1 !== undefined && team_employees_catagory_1 !== null && team_employees_catagory_1.length > 0 ? (team_employees_catagory_1.map((data1) => (<>
                                        {data1.designations_devide.length > 0 && (data1.designations_devide.map((data2) => (
                                            <SwiperSlide style={{ paddingBottom: "5rem" }} >
                                                <div className="single-team">
                                                    <div className="team-image" style={{ width: "100%" }}>
                                                        <img style={{ minWidth: "100%", minHeight: "100%" }} src={(data2.photo !== null && data2.photo.length > 0 ? (`${multi_image_url}/uploads/employee-photo/${data2.photo}`) : (Team_1))}
                                                            alt="" />
                                                    </div>
                                                    <div className="team-member-details">
                                                        <h4>{data2.name}</h4>
                                                        <p>{data1.name}</p>
                                                        <Link to={`/Manage_profile/${data2.user_id}`}>সম্পূর্ণ প্রোফাইল</Link>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )))}
                                        {data1.designations_devide.length == 0 && updatemptyteam('', true)}
                                        {empty_team.current == team_employees_catagory_1.length && (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                    </>
                                    ))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}


                                    <div className="swiper-pagination"></div>
                                </Swiper>
                            </div>
                            <div className={"team_member_by_catagory d-none"} id="official">
                                <Swiper
                                    slidesPerView={2}
                                    spaceBetween={20}
                                    grabCursor={true}
                                    loop={true}
                                    pagination={{
                                        el: '.swiper-pagination',
                                        clickable: true,
                                        renderBullet: function (index, className) {
                                            return '<span class="' + className + ' my_sigle_bullet_color_1"></span>';
                                        },
                                    }}
                                    modules={[Pagination]}
                                    breakpoints={{

                                        640: {
                                            slidesPerView: 2,
                                        },


                                        768: {
                                            slidesPerView: 3,
                                        },
                                        1200: {
                                            slidesPerView: 4
                                        }
                                    }}
                                    className="mySwiper">
                                    {updatemptyteam(0, false)}
                                    {team_employees_catagory_2 !== undefined && team_employees_catagory_2 !== null && team_employees_catagory_2.length > 0 ? (team_employees_catagory_2.map((data1) => (<>
                                        {data1.designations_devide.length > 0 && (data1.designations_devide.map((data2) => (
                                            <SwiperSlide style={{ paddingBottom: "5rem" }} >
                                                <div className="single-team">
                                                    <div className="team-image" style={{ width: "100%" }}>
                                                        <img style={{ minWidth: "100%", minHeight: "100%" }} src={(data2.photo !== null && data2.photo.length > 0 ? (`${multi_image_url}/uploads/employee-photo/${data2.photo}`) : (Team_1))}
                                                            alt="" />
                                                    </div>
                                                    <div className="team-member-details">
                                                        <h4>{data2.name}</h4>
                                                        <p>{data1.name}</p>
                                                        <Link to={`/Manage_profile/${data2.user_id}`}>সম্পূর্ণ প্রোফাইল</Link>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )))}
                                        {data1.designations_devide.length == 0 && updatemptyteam('', true)}
                                        {empty_team.current == team_employees_catagory_2.length && (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}

                                    </>
                                    ))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}


                                    <div className="swiper-pagination"></div>
                                </Swiper>
                            </div>
                            <div className={"team_member_by_catagory d-none"} id="teacher">
                                <Swiper
                                    slidesPerView={2}
                                    spaceBetween={20}
                                    grabCursor={true}
                                    loop={true}
                                    pagination={{
                                        el: '.swiper-pagination',
                                        clickable: true,
                                        renderBullet: function (index, className) {
                                            return '<span class="' + className + ' my_sigle_bullet_color_1"></span>';
                                        },
                                    }}
                                    modules={[Pagination]}
                                    breakpoints={{

                                        640: {
                                            slidesPerView: 2,
                                        },


                                        768: {
                                            slidesPerView: 3,
                                        },
                                        1200: {
                                            slidesPerView: 4
                                        }
                                    }}
                                    className="mySwiper">
                                    {team_teachers !== undefined && team_teachers !== null ? (team_teachers.map((data) => (
                                        <SwiperSlide style={{ paddingBottom: "5rem" }} >
                                            <div className="single-team">
                                                <div className="team-image" style={{ width: "100%" }}>
                                                    <img style={{ minWidth: "100%", minHeight: "100%" }} src={(data.photo !== null && data.photo.length > 0 ? (`${multi_image_url}/uploads/teacher-photo/${data.photo}`) : (Team_1))}
                                                        alt="" />
                                                </div>
                                                <div className="team-member-details">
                                                    <h4>{data.name}</h4>
                                                    <p>শিক্ষক</p>
                                                    <Link to={`/Manage_profile/${data.user_id}`}>সম্পূর্ণ প্রোফাইল</Link>
                                                </div>
                                            </div>
                                        </SwiperSlide>))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}

                                    <div className="swiper-pagination"></div>
                                </Swiper>
                            </div>
                            <div className={"team_member_by_catagory d-none "} id="employee">
                                <Swiper
                                    slidesPerView={2}
                                    spaceBetween={20}
                                    grabCursor={true}
                                    loop={true}
                                    pagination={{
                                        el: '.swiper-pagination',
                                        clickable: true,
                                        renderBullet: function (index, className) {
                                            return '<span class="' + className + ' my_sigle_bullet_color_1"></span>';
                                        },
                                    }}
                                    modules={[Pagination]}
                                    breakpoints={{

                                        640: {
                                            slidesPerView: 2,
                                        },


                                        768: {
                                            slidesPerView: 3,
                                        },
                                        1200: {
                                            slidesPerView: 4
                                        }
                                    }}
                                    className="mySwiper">
                                    {updatemptyteam(0, false)}
                                    {team_employees_catagory_3 !== undefined && team_employees_catagory_3 !== null && team_employees_catagory_3.length > 0 ? (team_employees_catagory_3.map((data1) => (<>
                                        {data1.designations_devide.length > 0 && (data1.designations_devide.map((data2) => (
                                            <SwiperSlide style={{ paddingBottom: "5rem" }} >
                                                <div className="single-team">
                                                    <div className="team-image" style={{ width: "100%" }}>
                                                        <img style={{ minWidth: "100%", minHeight: "100%" }} src={(data2.photo !== null && data2.photo.length > 0 ? (`${multi_image_url}/uploads/employee-photo/${data2.photo}`) : (Team_1))}
                                                            alt="" />
                                                    </div>
                                                    <div className="team-member-details">
                                                        <h4>{data2.name}</h4>
                                                        <p>{data1.name}</p>
                                                        <Link to={`/Manage_profile/${data2.user_id}`}>সম্পূর্ণ প্রোফাইল</Link>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )))}
                                        {data1.designations_devide.length == 0 && updatemptyteam('', true)}
                                        {empty_team.current == team_employees_catagory_2.length && (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                    </>
                                    ))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}


                                    <div className="swiper-pagination"></div>
                                </Swiper>
                            </div>


                            {/* <div className="tabcontent" id="advisoryBorde">
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={20}
                                    grabCursor={true}
                                    loop={true}
                                    pagination={{
                                        el: '.swiper-pagination',
                                        clickable: true,
                                        renderBullet: function (index, className) {
                                            return '<span class="' + className + ' my_sigle_bullet_color_1"></span>';
                                        },
                                    }}
                                    modules={[Pagination]}
                                    breakpoints={{
                                        
                                        640: {
                                            slidesPerView: 2,
                                        },

                                       
                                        768: {
                                            slidesPerView: 3,
                                        },
                                        1200: {
                                            slidesPerView: 4
                                        }
                                    }}
                                    className="mySwiper">
                                    {Object.keys(dataTeam_advisoryBorde).map((key2) => (

                                        <SwiperSlide style={{ paddingBottom: "5rem" }} >

                                            <div className="single-team">
                                                <div className="team-image">
                                                    <img src={dataTeam_advisoryBorde[key2].single_link}
                                                        alt="" />
                                                </div>
                                                <div className="team-member-details">
                                                    <h4>{dataTeam_advisoryBorde[key2].title}</h4>
                                                    <p>{dataTeam_advisoryBorde[key2].short_description}</p>
                                                    <Link to={'/Manage_profile'}>{dataTeam_advisoryBorde[key2].long_description}</Link>
                                                </div>
                                            </div>
                                        </SwiperSlide>

                                    ))}

                                 
                                    <div className="swiper-pagination"></div>
                                </Swiper>
                            </div> */}

                        </div>
                    </div>
                </div >
            </section >


            <section id="news" >
                <div className="container" style={{ padding: " 0 5rem " }}>

                    <div className="section-title">
                        <h2>নিউজ</h2>
                        <p>কওমী মাদরাসা শিক্ষা বোর্ড (বেফাক) এর কেন্দীয় পরীক্ষায় মেধা তালিকায় দ্বিতীয় ও তৃতীয় স্থান অধিকার সহ হুফফাজুল কুরআন ফাউন্ডেশনের প্রতিযোগিতায় প্রতিষ্ঠানটির অর্জন হয়েছে অসংখ্য পুরুষ্কার</p>
                    </div>
                    <div className="row">
                        {news !== null && news !== undefined ? (news.map((data, index) => (
                            <div className="col-lg-4 col-md-4 col-12 mx-auto">
                                <div className="single-news hover_parent">
                                    <div className="news-image hover_child"
                                        style={{ backgroundImage: `url(${multi_image_url}/uploads/news/${data.image})` }}></div>
                                    <div className="news-details">
                                        <h4>{data.title.slice(0, 60)}...</h4>
                                        <p style={{ textAlign: "justify" }}>{data.news.slice(0, 180)} ...</p>
                                        <Link to={"/News/" + data.id}>বিস্তারিত পড়ুন</Link>
                                    </div>
                                </div>
                            </div>
                        ))) :
                            (
                                <><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>
                            )
                        }
                        {/* <div className="col-lg-4 col-md-4 col-12">
                            <div className="single-news">
                                <div className="news-image"
                                    style={{ backgroundImage: `url(${News_1})` }}></div>
                                <div className="news-details">
                                    <h4>Two More Removed</h4>
                                    <p>In its bid to crackdown on tax avoidance and evasion, the European Union has blacklisted
                                        and “grey-listed”</p>
                                    <a href="#">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="single-news">
                                <div className="news-image"
                                    style={{ backgroundImage: `url(${News_1})` }}></div>
                                <div className="news-details">
                                    <h4>Changes to UAE</h4>
                                    <p>The United Arab Emirates, which previously did not allow 100% ownership of foreign
                                        business entities or long residency</p>
                                    <a href="#">Read More</a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section >


            <section id="testimonial">
                <div className="container" style={{ padding: " 0 5rem " }}>
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title">
                                <h2>আকাবির ও অভিভাবকের মন্তব্য</h2>
                            </div>
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={20}
                                grabCursor={true}
                                loop={true}
                                pagination={{
                                    el: '.swiper-pagination',
                                    clickable: true,
                                    renderBullet: function (index, className) {
                                        return '<span class="' + className + ' my_sigle_bullet"></span>';
                                    },
                                }}
                                modules={[Pagination]}
                                breakpoints={{
                                    // when window width is >= 640px
                                    640: {
                                        slidesPerView: 1,
                                    },
                                    // when window width is >= 768px
                                    768: {
                                        slidesPerView: 2,
                                    },
                                }}
                                className="mySwiper">
                                {testimonials !== null && testimonials !== undefined ? ((testimonials).map((data) => (
                                    <SwiperSlide style={{ paddingBottom: '5rem' }}>
                                        <div className="single-testimonial" style={{ height: "20rem" }}>
                                            <div className="testimonial-author" style={{ height: "6rem", width: "6rem" }}>
                                                <div style={{ borderRadius: "100%", height: "6rem", width: "6rem", overflow: "hidden", }}>
                                                    <img style={{ minHeight: "100%", minWidth: "100%" }} src={data.image !== null && data.image.length > 0 ? (`${multi_image_url}/uploads/testimonial/${data.image}`) : (Team_1)}
                                                        alt="" />
                                                </div>

                                            </div>
                                            <div className="testimonial-autor-details">
                                                <h4>{data.name}</h4>
                                                <p>{data.title}</p>
                                            </div>
                                            <div className="testimonial-contents">
                                                <p style={{ textAlign: "justify", overflow: "hidden", }}><i>{data.message}</i>
                                                </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                {/* <SwiperSlide style={{ paddingBottom: '3rem' }}>
                                    <div className="single-testimonial">
                                        <div className="testimonial-author">
                                            <img src={Testimonial_1}
                                                alt="" />
                                        </div>
                                        <div className="testimonial-autor-details">
                                            <h4>Richard Smith</h4>
                                            <p>Co-Founder, Company</p>
                                        </div>
                                        <div className="testimonial-contents">
                                            <p><i>Far far away, behind the word mountains, far from the countries Vokalia and
                                                Consonantia, there live the blind texts. Separated they live in Bookmarksgrove
                                                right at the coast of the Semantics, a large language ocean. A small river named
                                                Duden flows by their place and supplies it with the necessary regelialia.</i>
                                            </p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide style={{ paddingBottom: '3rem' }}>
                                    <div className="single-testimonial">
                                        <div className="testimonial-author">
                                            <img src={Testimonial_1}
                                                alt="" />
                                        </div>
                                        <div className="testimonial-autor-details">
                                            <h4>Richard Smith</h4>
                                            <p>Co-Founder, Company</p>
                                        </div>
                                        <div className="testimonial-contents">
                                            <p><i>Far far away, behind the word mountains, far from the countries Vokalia and
                                                Consonantia, there live the blind texts. Separated they live in Bookmarksgrove
                                                right at the coast of the Semantics, a large language ocean. A small river named
                                                Duden flows by their place and supplies it with the necessary regelialia.</i>
                                            </p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide style={{ paddingBottom: '3rem' }}>
                                    <div className="single-testimonial">
                                        <div className="testimonial-author">
                                            <img src={Testimonial_1}
                                                alt="" />
                                        </div>
                                        <div className="testimonial-autor-details">
                                            <h4>Richard Smith</h4>
                                            <p>Co-Founder, Company</p>
                                        </div>
                                        <div className="testimonial-contents">
                                            <p><i>Far far away, behind the word mountains, far from the countries Vokalia and
                                                Consonantia, there live the blind texts. Separated they live in Bookmarksgrove
                                                right at the coast of the Semantics, a large language ocean. A small river named
                                                Duden flows by their place and supplies it with the necessary regelialia.</i>
                                            </p>
                                        </div>
                                    </div>
                                </SwiperSlide> */}
                                <div className="swiper-pagination"></div>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>

            <section id="blog" className='event_container'>
                <div className="container text-center" style={{ padding: " 0 5rem " }}>

                    <div className="section-title">
                        <h2>ইভেন্ট</h2>
                        <p>কওমী মাদরাসা শিক্ষা বোর্ড (বেফাক) এর কেন্দীয় পরীক্ষায় মেধা তালিকায় দ্বিতীয় ও তৃতীয় স্থান অধিকার সহ হুফফাজুল কুরআন ফাউন্ডেশনের প্রতিযোগিতায় প্রতিষ্ঠানটির অর্জন হয়েছে অসংখ্য পুরুষ্কার</p>
                    </div>

                    {events !== null && events !== undefined ? (events.map((data, index) => (
                        <>
                            {index == 0 &&
                                <div class=" single-news card mb-5" >
                                    <div class="row g-0 hover_parent">
                                        <div class="col-lg-6">
                                            <img src={`${multi_image_url}/uploads/event/${data.image}`} className="card-img-top hover_child" alt="..." />
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="news-details text-left">
                                                <h2 className='card-title font-weight-bold mt-2' style={{ fontSize: "1.5rem" }}>{data.title.slice(0, 35)}...</h2>
                                                <p className='mt-4' style={{ textAlign: "justify" }}>{data.note.length > 0 ? data.note.slice(0, 250) : ''} ...</p>
                                                <Link to={"/Event/" + data.id} className="">বিস্তারিত পড়ুন</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                /* <div div className="row mb-5" >
                                <div className="col-12 col-lg-7 ">
                                    <img className='single-news' src={`${multi_image_url}/uploads/event/${data.image}`} />
                                </div>
                                <div className="col-12 col-lg-5 text-left  px-4">
                                    <h2 className='font-weight-bold mt-2'>{data.title.slice(0, 35)}...</h2>
                                    <p className='mt-4'>{data.note.length > 0 ? data.note.slice(0, 490) : ''} ...</p>
                                    <Link to={"/Event/" + data.id} className="btn btn-primary">Go somewhere</Link>
                                </div>
                            </div>} */
                            }

                        </>
                    ))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}

                    <div className='row pt-2 text-left'>
                        {events !== null && events !== undefined ? (events.map((data, index) => (
                            <>
                                {index !== 0 &&
                                    <div className="col-12 col-md-4 col-lg-4 ">
                                        <div className="single-news card hover_parent" style={{ width: 'auto' }}>
                                            <img src={`${multi_image_url}/uploads/event/${data.image}`} className="card-img-top hover_child" alt="..." />
                                            <div className="news-details">
                                                <h5 className="card-title">{data.title.slice(0, 25)}...</h5>
                                                <p className="card-text" style={{ textAlign: "justify" }}>{data.note.slice(0, 180)} ...</p>

                                                <Link to={"/Event/" + data.id} className="" style={{ textAlign: "left" }}>বিস্তারিত পড়ুন</Link>

                                            </div>
                                        </div>
                                    </div>
                                }</>))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}

                        {/* <div className="col-12 col-md-4 col-lg-4 ">
                            <div className="card single-news" style={{ width: 'auto' }}>
                                <img src="https://source.unsplash.com/random/360x260?science,nature" className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Card title</h5>
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" className="btn btn-primary">Go somewhere</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4 ">
                            <div className="card single-news" style={{ width: 'auto' }}>
                                <img src="https://source.unsplash.com/random/360x260?science" className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Card title</h5>
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" className="btn btn-primary">Go somewhere</a>
                                </div>
                            </div>
                        </div> */}

                    </div >
                </div>
            </section >


        </>
    )
}
