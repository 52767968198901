import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Http from "../Http_Api";
import dataContext from '../context/data/dataContext';
export default function Notice() {
    const windoSCrollCount = useRef(0)

    const context = useContext(dataContext);
    const { data, multi_image_url } = context;

    useEffect(() => {
        if (windoSCrollCount.current === 0) {
            // console.log('asdfasfsa')
            window.scrollTo({ top: 0, behavior: "smooth" })
            windoSCrollCount.current = 1
        }
        let scrolling = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop


    }, [])

    var notices;
    if (data !== null && data !== undefined && data.length > 0) {
        var { notices } = data[0];
    }

    if (notices !== undefined) {
        let val = [];
        notices.map((data) => {
            val.push(data);
        })
        notices = val;

    }
    return (
        <>
            <section id="page-banner">
                <div className="banner-inner"

                    style={{ backgroundImage: `url(https://source.unsplash.com/random/1600x200/?islamic)` }}>

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="banner-contents">
                                    <h1 className='d-block'>নোটিশ</h1>
                                    <nav className="breadcrumbs " style={{ opacity: "1" }}>
                                        <Link to={'/'} className="breadcrumbs__item"><i className="text-black fa-solid fa-house"></i> হোম</Link>
                                        {/* <Link to={'/'} className="breadcrumbs__item">Shop</Link> */}
                                        {/* <Link to={'/'} className="breadcrumbs__item">Cart</Link> */}
                                        <Link to={'/NoticeBorde'} className="breadcrumbs__item is-active">নোটিশ</Link>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='Notice'>
                <div className="container" style={{ marginTop: '5rem' }}>
                    {/* <div className="row " > */}
                    {/* <!-- Add Notice Area Start Here --> */}
                    {/* <div className="col-4-xxxl col-12">
                            <div className="card height-auto">
                                <div className="card-body">
                                    <div className="heading-layout1">
                                        <div className="item-title">
                                            <h3>Create A Notice</h3>
                                        </div>
                                        <div className="dropdown">
                                            <a className="dropdown-toggle" href="#" role="button"
                                                data-toggle="dropdown" aria-expanded="false">...</a>

                                            <div className="dropdown-menu dropdown-menu-right">
                                                <a className="dropdown-item" href="#"><i className="fas fa-times text-orange-red"></i>Close</a>
                                                <a className="dropdown-item" href="#"><i className="fas fa-cogs text-dark-pastel-green"></i>Edit</a>
                                                <a className="dropdown-item" href="#"><i className="fas fa-redo-alt text-orange-peel"></i>Refresh</a>
                                            </div>
                                        </div>
                                    </div>
                                    <form className="new-added-form">
                                        <div className="row">
                                            <div className="col-12-xxxl col-lg-6 col-12 form-group">
                                                <label>Title</label>
                                                <input type="text" placeholder="" className="form-control" />
                                            </div>
                                            <div className="col-12-xxxl col-lg-6 col-12 form-group">
                                                <label>Details</label>
                                                <input type="text" placeholder="" className="form-control" />
                                            </div>
                                            <div className="col-12-xxxl col-lg-6 col-12 form-group">
                                                <label>Posted By </label>
                                                <input type="text" placeholder="" className="form-control" />
                                                <i className="fas fa-user"></i>
                                            </div>
                                            <div className="col-12-xxxl col-lg-6 col-12 form-group">
                                                <label>Date</label>
                                                <input type="text" placeholder="" className="form-control air-datepicker" />
                                                <i className="far fa-calendar-alt"></i>
                                            </div>
                                            <div className="col-12 form-group mg-t-8">
                                                <button type="submit" className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">Save</button>
                                                <button type="reset" className="btn-fill-lg bg-blue-dark btn-hover-yellow">Reset</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div> */}
                    {/* <!-- Add Notice Area End Here --> */}
                    {/* <!-- All Notice Area Start Here --> */}
                    {/* <div className="col-8-xxxl col-12"> */}
                    <div className="card height-auto" style={{ border: "0" }}>
                        <div className="card-body">
                            <div className="heading-layout1">
                                <div className="item-title">
                                    <h3>নোটিশ বোর্ড</h3>
                                </div>

                            </div>
                            {/* <form className="mg-b-20">
                                <div className="row gutters-8">
                                    <div className="col-lg-5 col-12 form-group">
                                        <input type="text" placeholder="Search by Date ..." className="form-control" />
                                    </div>
                                    <div className="col-lg-5 col-12 form-group">
                                        <input type="text" placeholder="Search by Title ..." className="form-control" />
                                    </div>
                                    <div className="col-lg-2 col-12 form-group">
                                        <button type="submit" className="btn btn-outline-success">SEARCH</button>
                                    </div>
                                </div>
                            </form> */}
                            <div className="notice-board-wrap mt-4">
                                {notices !== undefined && notices !== null && notices.length > 0 ? (notices.map((data) => (
                                    <div className="notice-list">
                                        <div className={"post-date bg-green-primary"}>{data.date}</div>

                                        <h6 className="notice-title"><a href="#">{data.title}</a></h6>
                                        <div className="entry-meta"> {data.notice}
                                            {/* <span>5 min ago</span> */}
                                        </div>
                                    </div>
                                ))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                {/* <div className="notice-list">
                                    <div className="post-date bg-yellow">16 June, 2019</div>
                                    <h6 className="notice-title"><a href="#">Great School Great School manag mene esom
                                        text of the printing Great School manag mene esom  text of the printing manag
                                        mene esom  text of the printing.</a></h6>
                                    <div className="entry-meta">  Jennyfar Lopez / <span>5 min ago</span></div>
                                </div>
                                <div className="notice-list">
                                    <div className="post-date bg-pink">16 June, 2019</div>
                                    <h6 className="notice-title"><a href="#">Great School Great School manag mene esom
                                        text of the printing Great School manag mene esom  text of the printing manag
                                        mene esom  text of the printing.</a></h6>
                                    <div className="entry-meta">  Jennyfar Lopez / <span>5 min ago</span></div>
                                </div>
                                <div className="notice-list">
                                    <div className="post-date bg-skyblue">16 June, 2019</div>
                                    <h6 className="notice-title"><a href="#">Great School Great School manag mene esom
                                        text of the printing Great School manag mene esom  text of the printing manag
                                        mene esom  text of the printing.</a></h6>
                                    <div className="entry-meta">  Jennyfar Lopez / <span>5 min ago</span></div>
                                </div>
                                <div className="notice-list">
                                    <div className="post-date bg-yellow">16 June, 2019</div>
                                    <h6 className="notice-title"><a href="#">Great School Great School manag mene esom
                                        text of the printing Great School manag mene esom  text of the printing manag
                                        mene esom  text of the printing.</a></h6>
                                    <div className="entry-meta">  Jennyfar Lopez / <span>5 min ago</span></div>
                                </div>
                                <div className="notice-list">
                                    <div className="post-date bg-pink">16 June, 2019</div>
                                    <h6 className="notice-title"><a href="#">Great School Great School manag mene esom
                                        text of the printing Great School manag mene esom  text of the printing manag
                                        mene esom  text of the printing.</a></h6>
                                    <div className="entry-meta">  Jennyfar Lopez / <span>5 min ago</span></div>
                                </div>
                                <div className="notice-list">
                                    <div className="post-date bg-skyblue">16 June, 2019</div>
                                    <h6 className="notice-title"><a href="#">Great School Great School manag mene esom
                                        text of the printing Great School manag mene esom  text of the printing manag
                                        mene esom  text of the printing.</a></h6>
                                    <div className="entry-meta">  Jennyfar Lopez / <span>5 min ago</span></div>
                                </div>
                                <div className="notice-list">
                                    <div className="post-date bg-yellow">16 June, 2019</div>
                                    <h6 className="notice-title"><a href="#">Great School Great School manag mene esom
                                        text of the printing Great School manag mene esom  text of the printing manag
                                        mene esom  text of the printing.</a></h6>
                                    <div className="entry-meta">  Jennyfar Lopez / <span>5 min ago</span></div>
                                </div>
                                <div className="notice-list">
                                    <div className="post-date bg-pink">16 June, 2019</div>
                                    <h6 className="notice-title"><a href="#">Great School Great School manag mene esom
                                        text of the printing Great School manag mene esom  text of the printing manag
                                        mene esom  text of the printing.</a></h6>
                                    <div className="entry-meta">  Jennyfar Lopez / <span>5 min ago</span></div>
                                </div> */}
                            </div>
                            {/* </div>
                            </div> */}
                        </div>
                        {/* <!-- All Notice Area End Here --> */}
                    </div>
                </div>
            </section>

        </>
    )
}
